<template>
  <!-- SLIDE 4 -->
  <div class="section try-fetchup">
    <transition name="slide-fade">
      <div class="container-fluid h-100" v-if="active >= 4">
        <div class="row" style="height: 15%">
          <div class="col"></div>
        </div>
        <div class="row align-items-center" style="height: 85%">
          <div class="movil-img col-8 h-100 try-fetchup-img"></div>
          <div class="movil-text col-4 h-100">
            <div class="container h-100">
              <div class="row align-items-end h-50">
                <div class="col try-fetchup-text">
                  <h1 class="ml-1">
                    <div v-html="$t('stores_try_fetchup')"></div>
                  </h1>
                </div>
              </div>
              <div class="row align-items-start h-50">
                <div
                  class="col-12 text-left text-muted text-description"
                  v-html="$t('stores_try_fetchup_desc')"
                ></div>
                <div class="col-12 text-left btn-fu-login">
                  <a
                    target="blanck"
                    href="https://shopping.fetchup.io/register"
                  >
                    <b-button pill variant="outline-primary">{{
                      $t("register")
                    }}</b-button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    active: Number,
  },
};
</script>
<style lang="scss">
.try-fetchup {
  font-family: "DMSans", Roboto, Arial, sans-serif !important;

  .try-fetchup-img {
    background-image: url("/assets/5thSlideTablet.png");
    background-repeat: no-repeat;
    background-position: right;
    background-size: cover;
    z-index: 1;
    animation-name: animate-image-try;
    animation-duration: 3s;
  }

  .try-fetchup-text {
    height: 100%;
    display: flex;
    text-align: start;
    vertical-align: bottom;
    align-items: center;
    padding-top: 5%;
    animation-name: animationOfLeft-try-fetchup;
    animation-duration: 3s;
    z-index: 1;

    h1 {
      transform: translateX(1vw);
      z-index: 1;
      div {
        font-size: 3.5vw;
        font-weight: 800;
        color: black;
        width: 130%;
        transform: translateX(-20%);
        span {
          position: relative;
        }
        span:nth-child(2)::before {
          content: "";
          position: absolute;
          background-color: #e58d21;
          top: 20%;
          left: -5%;
          height: 70%;
          border-radius: 50px;
          width: 110%;
          z-index: -1;
          animation-name: animation-try-fetchup-span;
          animation-duration: 3.2s;
        }
        span:nth-child(1) {
          color: black;
        }
        span:nth-child(2) {
          color: white;
        }
      }
      div::after {
        content: "";
        position: absolute;
        background-image: url("/assets/3rdSlidePie.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        transform: translateX(-55%) translateY(-80%) rotateY(180deg);
        width: 30vw;
        height: 10vw;
        z-index: -2;
        animation-name: animationOpacityImg;
        animation-duration: 3.25s;
      }
    }
  }

  .text-description {
    font-size: 1.25vw;
    font-weight: 600;
    transform: translateY(-15vh);
    min-width: 50vw;
    animation-name: animationOfBottom-try-fetchup;
    animation-duration: 3s;
    z-index: 1;
  }
  .btn-fu-login {
    position: absolute;
    transform: translateY(-5vh);
    z-index: 1;
    animation-name: animation-try-fetchup-button;
    animation-duration: 3s;
    .btn-login {
      font-size: 1.25vw;
      cursor: pointer;
      color: #fff;
      background-color: #dc4510;
      border-color: #dc4510;
    }
  }

  .btn-outline-primary {
    background-color: #ffffffcc !important;
    border-color: #dc4510cc !important;
    color: #dc4510cc !important;
    font-weight: normal !important;
    font-size: 1.5vw !important;
    border-width: 2px !important;
  }
}

@keyframes animation-try-fetchup-button {
  0% {
    opacity: 0;
    transform: translateY(100vh);
  }
  25% {
    opacity: 0;
    transform: translateY(60vh);
  }
  50% {
    opacity: 0;
    transform: translateY(20vh);
  }
  75% {
    opacity: 0.1;
    transform: translateY(10vh);
  }
  100% {
    opacity: 1;
    transform: translateY(-5vh);
  }
}

@keyframes animate-image-try {
  from {
    transform: translateX(100vh) translateY(100vh);
  }
  to {
    transform: translateX(0vh) translateY(0vh);
  }
}

@keyframes animationOfBottom-try-fetchup {
  from {
    transform: translateY(100vh);
  }
  to {
    transform: translateY(-15vh);
  }
}

@keyframes animationOfLeft-try-fetchup {
  from {
    transform: translateX(-100vw);
  }
  to {
    transform: translateX(0vw);
  }
}

@keyframes animation-try-fetchup-span {
  from {
    transform: translateY(100vh) translateX(50vw);
  }
  to {
    transform: translateY(0vh) translateX(0vw);
  }
}

.es {
  .try-fetchup {
    h1 {
      div {
        span:nth-child(1) {
          //text-shadow: -2px -2px black;
        }
      }
    }
  }
}
</style>