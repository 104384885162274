<template>
  <div></div>
</template>
<script>
import platform from 'platform-detect/index.mjs';
export default {
  methods: {
    redirectAppStore() {
      if (platform.android) {
        window.location.replace(
          "https://play.google.com/store/apps/details?id=com.smartcity.fetchup.mx"
        );
      } else if (platform.ios) {
        window.location.replace(
          "https://apps.apple.com/mx/app/fetchup/id1529489977"
        );
      }  else {
        this.$router.push({ path: "/" });
      }
    },
  },
  created() {
    this.redirectAppStore();
  },
};
</script>
