<template>
  <!-- SLIDE 3 -->
  <div class="section shop-s3" id="slide3">
    <transition name="slide-fades">
      <div class="col-pfu container-fluid h-100" v-if="active >= 3">
        <div class="row align-items-start h-100">
          <div class="movil-img col-7 h-100">
            <div class="container cont20"></div>
            <div class="container cont80">
              <div class="row h-100 shop-s3-img"></div>
            </div>
          </div>
          <div class="movil-text col-5 h-100">
            <div class="container h-100">
              <div class="row align-items-end h-75">
                <div class="col shop-s3-text">
                  <h1 class="ml-1">
                    <div v-html="$t('shop_s3')">
                      <img
                        class="mb-3 ml-2"
                        src="/assets/4rdSlideSqueriel.png"
                      />
                    </div>
                  </h1>
                </div>
              </div>
              <div class="row align-items-start h-25">
                <div
                  class="col-10 text-left text-muted text-description"
                  v-html="$t('shop_s3_desc')"
                ></div>
                <div class="col stores-s3">
                  <div class="container">
                    <div class="row">
                      <div class="col-10">
                        <div class="container">
                          <div class="row h-25">
                            <div class="col">
                              <a
                                target="blanck"
                                href="https://apps.apple.com/mx/app/fetchup/id1529489977"
                              >
                                <img
                                  class="apple"
                                  src="/assets/logoBlackAppStore.svg"
                                />
                              </a>
                            </div>
                            <div class="col">
                              <a
                                target="blanck"
                                href="https://play.google.com/store/apps/details?id=com.smartcity.fetchup.mx"
                              >
                                <img
                                  class="android"
                                  src="/assets/logoBlackGooglePlay.svg"
                              /></a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-2"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  props: {
    active: Number,
  },
};
</script>
<style lang="scss">
.shop-s3 {
  font-family: "DMSans", Roboto, Arial, sans-serif !important;
  .cont20 {
    height: 20%;
  }
  .cont80 {
    height: 80%;
    padding: 0;
  }
  .shop-s3-img {
    background-image: url("/assets/4rdSlideBag.png");
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: cover;
    z-index: -2;
    animation-name: animationOfBottom;
    animation-duration: 2s;
  }
  .shop-s3-text {
    height: 100%;
    display: flex;
    text-align: start;
    vertical-align: bottom;
    align-items: center;
    padding-top: 10%;
    z-index: 1;
    animation-name: animationOfTop-shop-s3;
    animation-duration: 2s;

    h1 {
      transform: translateX(-7.5vw);
      z-index: 1;
      div {
        font-size: 3.5vw;
        font-weight: 800;
        color: black;
        span {
          position: relative;
        }
        span:before {
          content: "";
          position: absolute;
          top: 15%;
          left: -12.5%;
          height: 75%;
          background-color: #dc4510;
          border-radius: 1000px;
          width: 150%;
          z-index: -1;
          animation-name: animationOfTop-shop-s3-span;
          animation-duration: 2.2s;
        }
        span:nth-child(1)::before {
          top: 10%;
          left: -15%;
          height: 65%;
          width: 120%;
          background-color: #dc4510;
        }
        span {
          :nth-child(1)::before {
            background-color: unset;
          }
          span {
            //text-shadow: -2px -2px black;
            color: white;
          }
          ::after {
            content: "";
            position: absolute;
            background-image: url("/assets/4rdSlideSqueriel.png");
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            transform: translateX(12.5%);
            width: 3vw;
            height: 8vh;
          }
        }
      }
      div::before {
        content: "";
        position: absolute;
        background-image: url("/assets/2ndSlideDraw.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        transform: translateX(100%) translateY(-55%);
        width: 15vw;
        height: 20vh;
        z-index: -1;
        animation-name: animationOpacityImg;
        animation-duration: 2.5s;
      }
    }
  }

  .text-description {
    font-size: 1.25vw;
    font-weight: 600;
    transform: translateX(5%) translateY(-350%);
    animation-name: animationOfBottomDesc-shop-s3;
    animation-duration: 2s;
  }

  .stores-s3 {
    transform: translateY(-100%);
    img {
      width: 11.5vw;
    }
    .apple {
      animation-name: animationOfBottom;
      animation-duration: 2s;
    }
    .android {
      animation-name: animationOfBottom;
      animation-duration: 2.25s;
    }
  }

  @keyframes animationOfTop-shop-s3 {
    from {
      transform: translateY(-200vh);
    }
    to {
      transform: translateY(0vh);
    }
  }

  @keyframes animationOfTop-shop-s3-span {
    from {
      transform: translateY(-200vh) translateX(-50vw);
    }
    to {
      transform: translateY(0vh) translateX(0vw);
    }
  }

  @keyframes animationOfBottomDesc-shop-s3 {
    from {
      transform: translateY(100vh);
    }
    to {
      transform: translateX(5%) translateY(-370%);
    }
  }
}

.es {
  .shop-s3 {
    .text-description {
      transform: translateX(5%) translateY(-650%);
    }
  }
}
</style>