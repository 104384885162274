<template>
  <!-- SLIDE 3 -->
  <div class="section relax">
    <transition name="slide-fade">
      <div class="container-fluid h-100" v-if="active >= 2">
        <div class="row align-items-center h-100 col-pfu">
          <div class="col-fu col-sm-6 col-md-6 col-lg-6 h-100 relax-img"></div>
          <div class="col-fu col-sm-6 col-md-6 col-lg-6 h-100">
            <div class="container h-100">
              <div class="row align-items-end h-50">
                <div class="col relax-text">
                  <h1 class="ml-1">
                    <div v-html="$t('relax')"></div>
                  </h1>
                </div>
              </div>
              <div class="row align-items-start h-50">
                <div class="col-9">
                  <div
                    class="text-left text-muted text-description"
                    v-html="$t('homeRelax')"
                  ></div>
                  <div class="row h-25 align-items-start button-modal">
                    <router-link to="/Services?shop=true" style="width: 50%">
                      <b-button pill variant="outline-primary">
                        {{ $t("shop") }}</b-button
                      ></router-link
                    >
                  </div>
                </div>
                <div class="col-3"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    active: Number,
  },
};
</script>
<style lang="scss">
.relax {
  font-family: "DMSans", Roboto, Arial, sans-serif !important;

  .relax-img {
    background-image: url("/assets/3rdSlideGirls.png");
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;
    z-index: 1;
    animation-name: animationOfRight-relax;
    animation-duration: 3s;
  }

  .relax-text {
    height: 100%;
    display: flex;
    text-align: start;
    vertical-align: bottom;
    align-items: center;
    padding-top: 10%;
    animation-name: animationOfRight-relax;
    animation-duration: 3s;
    z-index: 1;

    h1 {
      transform: translateX(-4vw);
      z-index: 1;
      div {
        font-size: 3.5vw;
        font-weight: 800;
        color: black;
        // //text-shadow: 2px 2px 6px grey;
        span {
          position: relative;
        }
        span:nth-child(2) {
          color: white;
          // //text-shadow: -2px -2px 4px black;
        }
        span:nth-child(1)::before {
          content: "";
          position: absolute;
          background-color: #e58d21;
          top: 37.5%;
          left: 0;
          height: 60%;
          border-radius: 50px;
          width: 115%;
          transform: translateX(-13.5%);
          z-index: -1;
          animation-name: animationOfRightSpan13;
          animation-duration: 1.875s;
        }
        span:nth-child(2)::before {
          content: "";
          position: absolute;
          top: 5%;
          left: 0;
          bottom: 25%;
          height: 70%;
          background-color: #dc4510;
          border-radius: 1000px;
          width: 115%;
          transform: translateX(-6%);
          z-index: -1;
          animation-name: animationOfRightSpan6;
          animation-duration: 1.875s;
        }
      }
      div::after {
        content: "";
        position: absolute;
        background-image: url("/assets/3rdSlidePie.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        transform: translateX(-70%) translateY(-60%);
        width: 30vw;
        height: 10vw;
        z-index: -1;
        animation-name: animationOpacityImg;
        animation-duration: 3.25s;
      }
    }
  }

  .text-description {
    font-size: 1vw;
    font-weight: 600;
    animation-name: animationOfRight-relax;
    animation-duration: 3s;
  }

  .button-modal {
    margin-top: 5%;
    animation-name: animationOfRight-relax;
    animation-duration: 3s;
    .btn-outline-primary {
      background-color: transparent !important;
      border-color: #dc4510cc !important;
      color: #dc4510cc !important;
      font-weight: normal !important;
      font-size: 1.5vw !important;
      border-width: 2px !important;
      width: 100% !important;
    }
  }
}

.es {
  .relax {
    h1 {
      div {
        width: 80%;
      }
      div::after {
        transform: translateX(10%) translateY(-60%);
      }
    }
  }
}

@keyframes animationOfRight-relax {
  from {
    transform: translateX(100vw);
  }
  to {
    transform: translateX(0vw);
  }
}

@keyframes animationOfBottom {
  from {
    transform: translateY(100vh);
  }
  to {
    transform: translateY(0vh);
  }
}

@keyframes animationOfRightSpan13 {
  from {
    transform: translateX(100vw);
  }
  to {
    transform: translateX(-13.5%);
  }
}

@keyframes animationOfRightSpan6 {
  from {
    transform: translateX(100vw);
  }
  to {
    transform: translateX(-6%);
  }
}

@keyframes animationOpacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>