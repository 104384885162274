<template>
  <!-- SLIDE 3 -->
  <div class="section plazas-s4">
    <transition name="slide-fades">
      <div class="container-fluid h-100" v-if="active >= 3">
        <div class="row align-items-start h-100">
          <div class="movil-text col-5 h-100">
            <div class="container h-100">
              <div class="row align-items-end h-75">
                <div class="col plazas-s4-text">
                  <h1 class="ml-1">
                    <div v-html="$t('plazas_s4')"></div>
                  </h1>
                </div>
              </div>
              <div class="row align-items-start h-25">
                <div class="col"></div>
                <div
                  class="col-10 text-left text-muted text-description"
                  v-html="$t('plazas_s4_desc')"
                ></div>
                <div class="col-12 text-left btn-fu-login">
                  <router-link to="/Contact">
                    <b-button pill variant="outline-primary">{{
                      $t("contact_")
                    }}</b-button>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="col-7 h-100">
            <div
              class="movil-img row align-items-start h-100 plazas-s4-img"
            ></div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  props: {
    active: Number,
  },
};
</script>
<style lang="scss">
.plazas-s4 {
  font-family: "DMSans", Roboto, Arial, sans-serif !important;
  .plazas-s4-img {
    background-image: url("/assets/4thSlideMallFront.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: 1;
    animation-name: animationOfRight-plazas-s4;
    animation-duration: 2s;
  }
  .plazas-s4-text {
    height: 100%;
    display: flex;
    text-align: start;
    vertical-align: bottom;
    align-items: center;
    padding-top: 30%;
    z-index: 1;
    animation-name: animationOfLeftText-plazas-s4;
    animation-duration: 2s;

    h1 {
      transform: translateX(1.5vw);
      z-index: 1;
      div {
        font-size: 3.5vw;
        font-weight: 800;
        color: black;
        width: 60vw;
        span {
          position: relative;
        }
        span:nth-child(1) {
          color: white;
        }
        span:nth-child(1):before {
          content: "";
          position: absolute;
          top: 10%;
          left: -10%;
          height: 70%;
          background-color: #dc4510;
          border-radius: 1000px;
          width: 120%;
          z-index: -1;
          animation-name: animationOfLeftSpan2-plazas-s4;
          animation-duration: 2.2s;
        }
        span:nth-child(3):before {
          content: "";
          position: absolute;
          top: 10%;
          left: -5%;
          height: 70%;
          background-color: #e58d21;
          border-radius: 1000px;
          width: 120%;
          z-index: -1;
          animation-name: animationOfLeftSpan1-plazas-s4;
          animation-duration: 2.2s;
        }
      }
      div::before {
        content: "";
        position: absolute;
        background-image: url("/assets/3rdSlidePie.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        transform: translateX(-30%) translateY(-75%) rotateY(180deg);
        width: 25vw;
        height: 22.5vh;
        z-index: -1;
        animation-name: animationOpacityImg;
        animation-duration: 2.5s;
      }
    }
  }

  .text-description {
    font-size: 1.25vw;
    font-weight: 600;
    transform: translateX(-5vw) translateY(-100%);
    z-index: 1;
    animation-name: animationOfLeftDesc-plazas-s4;
    animation-duration: 2s;
  }

  .btn-fu-login {
    position: absolute;
    width: 40%;
    transform: translateX(1.5vw) translateY(5vh);
    z-index: 1;
    animation-name: animation-plazas-button;
    animation-duration: 2s;
    .btn-outline-primary {
      background-color: transparent !important;
      border-color: #dc4510cc !important;
      color: #dc4510cc !important;
      font-weight: normal !important;
      font-size: 1.5vw !important;
      border-width: 2px !important;
      width: 100% !important;
    }
  }

  @keyframes animation-plazas-button {
    0% {
      opacity: 0;
      transform: translateX(1.5vw) translateY(100vh);
    }
    25% {
      opacity: 0;
      transform: translateX(1.5vw) translateY(60vh);
    }
    50% {
      opacity: 0;
      transform: translateX(1.5vw) translateY(20vh);
    }
    75% {
      opacity: 0.1;
      transform: translateX(1.5vw) translateY(10vh);
    }
    100% {
      opacity: 1;
      transform: translateX(1.5vw) translateY(5vh);
    }
  }

  @keyframes animationOfRight-plazas-s4 {
    from {
      transform: translateY(-200vw);
    }
    to {
      transform: translateY(0vw);
    }
  }

  @keyframes animationOfLeftDesc-plazas-s4 {
    from {
      transform: translateX(-100vw);
    }
    to {
      transform: translateX(-5vw) translateY(-100%);
    }
  }

  @keyframes animationOfLeftText-plazas-s4 {
    from {
      transform: translateX(-100vw);
    }
    to {
      transform: translateX(0vw);
    }
  }

  @keyframes animationOfLeftSpan1-plazas-s4 {
    from {
      transform: translateX(-100vw) translateY(-50vh);
    }
    to {
      transform: translateX(0vw) translateY(0vh);
    }
  }

  @keyframes animationOfLeftSpan2-plazas-s4 {
    from {
      transform: translateX(-100vw) translateY(50vh);
    }
    to {
      transform: translateX(0vw) translateY(0vh);
    }
  }
}
</style>