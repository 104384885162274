<template>
  <div id="app">
    <navigation></navigation>
  </div>
</template>
<script>
import Navigation from "@/components/Navigation";
export default {
  components: {
    Navigation,
  },
};
</script>
<style lang="scss">
@font-face {
  font-family: "DMSans";
  font-weight: 400;
  font-style: normal;
  font-display: auto;
  src: local("DMSans"),
    url("/assets/fonts/DMSans-Regular.ttf") format("truetype"),
    url("/assets/fonts/DMSans-Bold.ttf") format("truetype"),
    url("/assets/fonts/DMSans-BoldItalic.ttf") format("truetype"),
    url("/assets/fonts/DMSans-Italic.ttf") format("truetype"),
    url("/assets/fonts/DMSans-Medium.ttf") format("truetype"),
    url("/assets/fonts/DMSans-MediumItalic.ttf") format("truetype");
}

:root {
  --font-family-sans-serif: "DMSans", Helvetica, Arial, sans-serif !important;
}

#app {
  font-family: "DMSans", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#fp-nav ul li a span,
.fp-slidesNav ul li a span {
  background: gray;
  height: 12px;
  width: 12px;
  margin: -6px 0 0 -6px;
  border-radius: 100%;
}

#fp-nav ul li a.active span,
#fp-nav ul li:hover a.active span,
.fp-slidesNav ul li a.active span,
.fp-slidesNav ul li:hover a.active span {
  background: #e18f00;
}

@media (min-width: 576px) {
  html {
    font-size: 0.5rem;
  }
}

@media (min-width: 768px) {
  html {
    font-size: 0.65rem;
  }
}

@media (min-width: 992px) {
  html {
    font-size: 0.7rem;
  }
}

@media (min-width: 1200px) {
  html {
    font-size: 0.85rem;
  }
}

@media (min-width: 1400px) {
  html {
    font-size: 1rem;
  }
  @media (min-width: 1200px) {
    .container,
    .container-sm,
    .container-md,
    .container-lg,
    .container-xl {
      max-width: 100% !important;
    }
  }
}

.modal-content {
  border-radius: 1rem !important;
  .modal-body {
    padding: 0px !important;
  }
}

.btn-outline-primary {
  background-color: transparent !important;
  border-color: white !important;
  color: white !important;
  font-weight: normal !important;
  font-size: 1.25vw !important;
  border-width: 2px !important;
}
</style>
