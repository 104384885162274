import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Services from '../views/Services.vue'
import Stores from '../views/Stores.vue'
import Plazas from '../views/Plazas.vue'
import Us from '../views/Us.vue'
import SignIn from '../views/SignIn.vue'
import Redirect from '../views/Download/Redirect.vue'
import Contact from '../views/Contact.vue'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: '',
        component: Home
    },
    {
        path: '/Services',
        name: 'Services',
        component: Services
    },
    {
        path: '/Stores',
        name: 'Stores',
        component: Stores
    },
    {
        path: '/Plazas',
        name: 'Plazas',
        component: Plazas
    },
    {
        path: '/Us',
        name: 'Us',
        component: Us
    },
    {
        path: '/SignIn',
        name: 'SignIn',
        component: SignIn
    },
    {
        path: '/download',
        name: 'Download',
        component: Redirect
    },
    {
        path: '/contact',
        name: 'Contact',
        component: Contact
    },
    {
        path: '*',
        redirect: '/'
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    linkExactActiveClass: "nav-item active"
})

export default router