<template>
  <!-- SLIDE 2 -->
  <div class="section plazas-s3">
    <transition name="slide-fade">
      <div class="container-fluid h-100" v-if="active >= 2">
        <div class="row" style="height: 10%">
          <div class="col"></div>
        </div>
        <div class="row align-items-center" style="height: 85%">
          <div class="movil-img col h-100 img-friends"></div>
          <div class="movil-text col h-100">
            <div class="container h-100">
              <div class="row align-items-end h-50">
                <div class="col plazas-s3-text">
                  <h1 class="ml-1">
                    <div v-html="$t('plazas_s3')"></div>
                  </h1>
                </div>
              </div>
              <div class="row align-items-start h-50">
                <div class="col-9">
                  <div
                    class="text-left text-muted text-description"
                    v-html="$t('plazas_s3_desc')"
                  ></div>
                  <div class="row h-25 align-items-start button-modal">
                    <b-button
                      pill
                      variant="outline-primary"
                      v-b-modal.modal-video-understand
                    >
                      {{ $t("readMore") }}</b-button
                    >
                  </div>
                </div>
                <div class="col-3"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" style="height: 5%">
          <div class="col"></div>
        </div>
      </div>
    </transition>
    <b-modal
      id="modal-video-understand"
      size="xl"
      centered
      :hide-footer="true"
      :hide-header="true"
      class="video"
    >
      <div class="d-block text-center body-modal-understand">
        <b-button
          variant="light"
          size="sm"
          class="mb-2 close-btn"
          @click="$bvModal.hide('modal-video-understand')"
        >
          <b-icon icon="x" aria-hidden="true"></b-icon>
        </b-button>
        <b-carousel
          id="carousel-1"
          v-model="slide"
          :interval="4000"
          background="#ababab"
          img-width="1024"
          img-height="480"
          style="text-shadow: 1px 1px 2px #333"
        >
          <!-- Text slides with image -->
          <b-carousel-slide
            caption="FetchUp"
            text="Nulla vitae elit libero, a pharetra augue mollis interdum."
            img-src="https://picsum.photos/1024/480/?image=52"
          ></b-carousel-slide>
        </b-carousel>
        <br />
        <h3>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae
          reprehenderit autem voluptatum est blanditiis debitis. Eligendi, rerum
          unde quae numquam veritatis dolores eius sequi vero nobis,
          exercitationem voluptate, cupiditate ratione.
        </h3>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    active: Number,
  },
  data() {
    return {
      slide: 0,
      sliding: null,
    };
  },
};
</script>
<style lang="scss">
.plazas-s3 {
  font-family: "DMSans", Roboto, Arial, sans-serif !important;

  .img-friends {
    background-image: url("/assets/3rdSlidePlazas.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: 1;
    animation-name: animation-plazas-s3;
    animation-duration: 2s;
  }

  .plazas-s3-text {
    height: 100%;
    display: flex;
    text-align: start;
    vertical-align: bottom;
    align-items: center;
    padding-top: 10%;
    animation-name: animation-plazas-s3-texts;
    animation-duration: 2s;
    z-index: 1;

    h1 {
      transform: translateX(-8.5vw);
      z-index: 1;
      div {
        font-size: 3.5vw;
        font-weight: 800;
        color: black;
        width: 150%;
        span {
          position: relative;
        }
        span:nth-child(1) {
          color: white;
        }
        span:nth-child(1):before {
          content: "";
          position: absolute;
          top: 10%;
          left: -5%;
          height: 70%;
          background-color: #dc4510;
          border-radius: 1000px;
          width: 120%;
          z-index: -1;
          animation-name: animationOfLeftSpan2-plazas-s3;
          animation-duration: 2.2s;
        }
        span:nth-child(2):before {
          content: "";
          position: absolute;
          top: 35%;
          left: -15%;
          height: 60%;
          background-color: #e58d21;
          border-radius: 1000px;
          width: 120%;
          z-index: -1;
          animation-name: animationOfLeftSpan1-plazas-s3;
          animation-duration: 2.2s;
        }
      }
      div::after {
        content: "";
        position: absolute;
        background-image: url("/assets/2ndSlideDraw.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        transform: translateX(-25%) translateY(-65%);
        width: 20vw;
        height: 30vh;
        z-index: -1;
        animation-name: animationOpacityImg;
        animation-duration: 2.5s;
      }
    }
  }

  .text-description {
    font-size: 1.25vw;
    font-weight: 600;
    transform: translateY(-6vh);
    animation-name: animation-plazas-s3-texts-desc;
    animation-duration: 2s;
  }

  .button-modal {
    width: 30%;
    margin-left: 0px !important;
    margin-top: 5%;
    transform: translateY(-6vh);
    animation-name: animation-plazas-s3-texts-desc;
    animation-duration: 2s;
    .btn-outline-primary {
      background-color: transparent !important;
      border-color: #dc4510cc !important;
      color: #dc4510cc !important;
      font-weight: normal !important;
      font-size: 1.5vw !important;
      border-width: 2px !important;
      width: 100% !important;
    }
  }

}

@keyframes animation-plazas-s3 {
  from {
    transform: translateY(-200vh);
  }
  to {
    transform: translateY(0vh);
  }
}

@keyframes animation-plazas-s3-texts-desc {
  from {
    transform: translateX(100vw) translateY(0vh);
  }
  to {
    transform: translateX(0vw) translateY(-6vh);
  }
}

@keyframes animation-plazas-s3-texts {
  from {
    transform: translateX(100vw);
  }
  to {
    transform: translateX(0vw);
  }
}

@keyframes animationOfLeftSpan1-plazas-s3 {
  from {
    transform: translateX(100vw) translateY(-50vh);
  }
  to {
    transform: translateX(0vw) translateY(0vh);
  }
}

@keyframes animationOfLeftSpan2-plazas-s3 {
  from {
    transform: translateX(100vw) translateY(50vh);
  }
  to {
    transform: translateX(0vw) translateY(0vh);
  }
}

.es{
  .plazas-s3{
    h1{
      div{
        
      }
    }
  }
}

.body-modal-understand {
  padding: 5%;
  .close-btn {
    position: absolute;
    right: 0.5%;
    top: 0.5%;
  }
}
</style>