<template>
  <div class="page-contact">
    <full-page ref="fullpage" :options="options">
      <div class="section contact">
        <div class="container h-100">
          <b-row class="justify-content-md-center form-contact">
            <b-form
              @submit="onSubmit"
              @reset="onReset"
              v-if="show"
              class="form-data"
            >
              <b-form-group
                id="input-group-1"
                label="Email address:"
                label-for="input-1"
                label-align="left"
                class="text-form"
              >
                <b-form-input
                  id="input-1"
                  v-model="form.email"
                  type="email"
                  placeholder="Enter email"
                  required
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-2"
                label="Your Name:"
                label-for="input-2"
                label-align="left"
                class="text-form"
              >
                <b-form-input
                  id="input-2"
                  v-model="form.name"
                  placeholder="Enter name"
                  required
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-3"
                label="Contact:"
                label-for="input-3"
                label-align="left"
                class="text-form"
              >
                <b-form-textarea
                  id="textarea"
                  v-model="form.text"
                  placeholder="Enter something..."
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
              </b-form-group>

              <b-button type="submit" variant="primary">Submit</b-button>
            </b-form>
          </b-row>
        </div>
      </div>
      <!-- <Footer></Footer> -->
    </full-page>
  </div>
</template>
<script>
// import Footer from "@/views/Footer";
export default {
  components: {
    // Footer,
  },
  data() {
    return {
      options: {
        licenseKey: "7FD5911B-F38C4985-A35FC134-394A56DE",
        scrollBar: false,
        navigation: false,
      },
      form: {
        email: "",
        name: "",
        text: "",
      },
      show: true,
    };
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
      alert(JSON.stringify(this.form));
    },
    onReset(event) {
      event.preventDefault();
      // Reset our form values
      this.form.email = "";
      this.form.name = "";
      this.form.food = null;
      this.form.checked = [];
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
  },
};
</script>
<style>
.slide-fade-enter-active {
  transition: all 0s ease-in;
}
.slide-fade-leave-active {
  transition: all 0.5s ease-out;
}
.slide-fade-leave-to {
  /* transform: translateX(50vw) translateY(-200vh); */
  opacity: 0;
}
</style>
<style lang="scss">
.page-contact {
  font-family: "DMSans", Roboto, Arial, sans-serif !important;
  // background-image: url("/assets/backgroundQRs.png");
  // background-position: bottom;
  // background-size: cover;
  .section {
    background-repeat: no-repeat;
  }

  .form-contact {
    margin-top: 15rem !important;
    .form-data {
      background-color: #ffffffbd;
      width: 40% !important;
      min-width: 40% !important;
      padding: 2.5%;
      border-radius: 2rem;
      .text-form {
        color: black;
      }
    }
  }

  .btn-primary {
    background-color: transparent !important;
    border-color: #dc4510cc !important;
    color: #dc4510cc !important;
    font-weight: normal !important;
    font-size: 1.5vw !important;
    border-width: 2px !important;
    width: 100% !important;
  }

  @keyframes animationOfBottom {
    from {
      transform: translateY(100vh);
    }
    to {
      transform: translateY(0vh);
    }
  }

  @keyframes animationOfTop {
    from {
      transform: translateY(-200vh);
    }
    to {
      transform: translateY(0vh);
    }
  }

  @keyframes animationOpacityImg {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    75% {
      opacity: 0.2;
    }
    100% {
      opacity: 1;
    }
  }
}
</style>