<template>
  <!-- SLIDE 3 -->
  <div class="section shop-s2">
    <transition name="slide-fades">
      <div class="col-pfu container-fluid h-100" v-if="active >= 1">
        <div class="row align-items-start h-100">
          <div class="col-2 h-100"></div>
          <div class="movil-img col-4 h-100">
            <div class="col h-100">
              <div class="container h-100">
                <div class="row align-items-end h-25"></div>
                <div class="row align-items-start h-75 shop-s2-img"></div>
              </div>
            </div>
          </div>
          <div class="movil-text col-6 h-100">
            <div class="container h-100">
              <div class="row align-items-end h-75">
                <div class="col shop-s2-text">
                  <h1 class="ml-1">
                    <div v-html="$t('shop_s2')"></div>
                  </h1>
                </div>
              </div>
              <div class="row align-items-start h-25">
                <div class="col"></div>
                <div class="col-10">
                  <div
                    class="text-left text-muted text-description"
                    v-html="$t('shop_s2_desc')"
                  ></div>
                  <div class="row h-25 align-items-start button-modal">
                    <b-button
                      pill
                      variant="outline-primary"
                      v-b-modal.modal-video-whims
                    >
                      <b-icon-play-fill></b-icon-play-fill>
                      {{ $t("homeWatchVideo") }}</b-button
                    >
                  </div>
                </div>
                <div class="col"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <b-modal
      id="modal-video-whims"
      size="xl"
      centered
      :hide-footer="true"
      :hide-header="true"
      class="video"
    >
      <b-embed
        type="iframe"
        aspect="16by9"
        src="https://www.youtube.com/embed/EC8_dRWTyQg?autoplay=1"
        crossorigin="anonymous"
        autoplay
        allowfullscreen
      ></b-embed>
    </b-modal>
  </div>
</template>
<script>
export default {
  props: {
    active: Number,
  },
};
</script>
<style lang="scss">
.shop-s2 {
  font-family: "DMSans", Roboto, Arial, sans-serif !important;
  .shop-s2-img {
    background-image: url("/assets/3rdSlideReqs.png");
    background-repeat: no-repeat;
    background-position: left;
    background-size: contain;
    transform: translateY(-7.5vh) translateX(7.5vh);
    z-index: 1;
    animation-name: animationOfRight-shop-s2;
    animation-duration: 2s;
  }
  .shop-s2-text {
    height: 100%;
    display: flex;
    text-align: start;
    vertical-align: bottom;
    align-items: center;
    padding-top: 27.5%;
    z-index: 1;
    animation-name: animationOfLeftText-shop-s2;
    animation-duration: 2s;

    h1 {
      transform: translateX(-14.5vw);
      z-index: 1;
      div {
        font-size: 3.5vw;
        font-weight: 800;
        color: black;
        width: 60vw;
        span {
          position: relative;
        }
        span:nth-child(1) {
          color: white;
        }
        span:nth-child(1):before {
          content: "";
          position: absolute;
          top: 40%;
          left: -15%;
          height: 55%;
          background-color: #e58d21;
          border-radius: 1000px;
          width: 115%;
          z-index: -1;
          animation-name: animationOfLeftSpan1-shop-s2;
          animation-duration: 2.2s;
        }
        span:nth-child(2):before {
          content: "";
          position: absolute;
          top: 5%;
          left: -17.5%;
          height: 60%;
          background-color: #dc4510;
          border-radius: 1000px;
          width: 330%;
          z-index: -1;
          animation-name: animationOfLeftSpan2-shop-s2;
          animation-duration: 2.2s;
        }
        span:nth-child(2) {
          ::before {
            background-color: unset;
          }
          span {
            color: white;
          }
        }
      }
      div::before {
        content: "";
        position: absolute;
        background-image: url("/assets/3rdSlidePie.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        transform: translateX(17.5%) translateY(-85%) rotateY(180deg);
        width: 20vw;
        height: 20vh;
        z-index: -1;
        animation-name: animationOpacityImg;
        animation-duration: 2.5s;
      }
    }
  }

  .text-description {
    font-size: 1.25vw;
    font-weight: 600;
    transform: translateX(-25%) translateY(-20vh);
    animation-name: animationOfLeftDesc-shop-s2;
    animation-duration: 2s;
  }

  .button-modal {
    margin-top: 5%;
    transform: translateX(-22.5%) translateY(-20vh);
    animation-name: animationOfLeftDesc-shop-s2-btn;
    animation-duration: 2s;
    .btn-outline-primary {
      background-color: transparent !important;
      border-color: #dc4510cc !important;
      color: #dc4510cc !important;
      font-weight: normal !important;
      font-size: 1.5vw !important;
      border-width: 2px !important;
    }
  }

  @keyframes animationOfRight-shop-s2 {
    from {
      transform: translateX(100vw);
    }
    to {
      transform: translateY(-7.5vh) translateX(7.5vh);
    }
  }

  @keyframes animationOfLeftDesc-shop-s2 {
    from {
      transform: translateX(-100vw);
    }
    to {
      transform: translateX(-25%) translateY(-20vh);
    }
  }

  @keyframes animationOfLeftDesc-shop-s2-btn {
    from {
      transform: translateX(-100vw);
    }
    to {
      transform: translateX(-22.5%) translateY(-20vh);
    }
  }

  @keyframes animationOfLeftText-shop-s2 {
    from {
      transform: translateX(100vw);
    }
    to {
      transform: translateX(0vw);
    }
  }

  @keyframes animationOfLeftSpan1-shop-s2 {
    from {
      transform: translateX(100vw) translateY(-50vh);
    }
    to {
      transform: translateX(0vw) translateY(0vh);
    }
  }

  @keyframes animationOfLeftSpan2-shop-s2 {
    from {
      transform: translateX(100vw) translateY(50vh);
    }
    to {
      transform: translateX(0vw) translateY(0vh);
    }
  }
}

.es{
  .shop-s2{
    h1{
      div{
        transform: translateX(12.5%);
        span:nth-child(1) {
          //text-shadow: -2px -2px black;
          color: white;
        }
        span:nth-child(2):before{
        width: 150%;
      }
      }
      
    }
  }
}
</style>