<template>
  <div class="home-screen">
    <full-page ref="fullpage" :options="options">
      <Welcome></Welcome>
      <ChangeGame :active="activePage"></ChangeGame>
      <Relax :active="activePage"></Relax>
      <ParkEffortlessly :active="activePage"></ParkEffortlessly>
      <Download :active="activePage"></Download>
      <Footer></Footer>
    </full-page>
  </div>
</template>
<script>
import Welcome from "@/views/Home/Welcome";
import ChangeGame from "@/views/Home/ChangeGame";
import Relax from "@/views/Home/Relax";
import ParkEffortlessly from "@/views/Home/ParkEffortlessly";
import Download from "@/views/Home/Download";
import Footer from "@/views/Footer";
export default {
  components: {
    Welcome,
    ChangeGame,
    Relax,
    ParkEffortlessly,
    Download,
    Footer,
  },
  data() {
    return {
      options: {
        licenseKey: "7FD5911B-F38C4985-A35FC134-394A56DE",
        scrollBar: false,
        navigation: true,
        onLeave: this.onLeaveMeth,
      },
      activePage: 0,
    };
  },
  methods: {
    onLeaveMeth(origin, destination) {
      // function (origin, destination)
      //let o = origin.index || 0;
      let d = destination.index || 0;
      this.activePage = d;
    },
  },
};
</script>
<style>
.slide-fade-enter-active {
  transition: all 0s ease-in;
}
.slide-fade-leave-active {
  transition: all 0.5s ease-out;
}
.slide-fade-leave-to {
  /* transform: translateX(50vw) translateY(-200vh); */
  opacity: 0;
}
</style>
<style lang="scss">
.home-screen {
  font-family: "DMSans", Roboto, Arial, sans-serif !important;
  .section {
    background-repeat: no-repeat;
  }

  @keyframes animationOpacityImg {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    75% {
      opacity: 0.2;
    }
    100% {
      opacity: 1;
    }
  }
}
</style>