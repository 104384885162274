<template>
  <!-- SLIDE 2 -->
  <div class="section shop-s1">
    <transition name="slide-fades">
      <div class="container-fluid h-100" v-if="active >= 2">
        <div class="row" style="height: 20%">
          <div class="col"></div>
        </div>
        <div class="row align-items-center col-pfu" style="height: 80%">
          <div class="movil-img col-7 h-100 shop-s1-img"></div>
          <div class="movil-text col-5 h-100">
            <div class="container h-100">
              <div class="row align-items-end h-50">
                <div class="col shop-s1-text">
                  <h1 class="ml-1">
                    <div v-html="$t('shop_s1')"></div>
                  </h1>
                </div>
              </div>
              <div class="row align-items-start h-50">
                <div class="col-9">
                  <div
                    class="text-left text-muted text-description"
                    v-html="$t('shop_s1_desc')"
                  ></div>
                  <div
                    class="row h-25 align-items-start button-modal"
                    style="margin-left: 0 !important"
                  >
                    <b-button
                      pill
                      variant="outline-primary"
                      v-b-modal.modal-video-need
                    >
                      {{ $t("readMore") }}</b-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <b-modal
      id="modal-video-need"
      size="xl"
      centered
      :hide-footer="true"
      :hide-header="true"
      class="video"
    >
      <div class="d-block text-center body-modal-need">
        <b-button
          variant="light"
          size="sm"
          class="mb-2 close-btn"
          @click="$bvModal.hide('modal-video-need')"
        >
          <b-icon icon="x" aria-hidden="true"></b-icon>
        </b-button>
        <h3>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae
          reprehenderit autem voluptatum est blanditiis debitis. Eligendi, rerum
          unde quae numquam veritatis dolores eius sequi vero nobis,
          exercitationem voluptate, cupiditate ratione.
          <br />
          <br />
          Lorem ipsum dolor sit amet consectetur adipisicing elit. In libero
          harum corrupti magnam provident? Minus, velit vel. Delectus ipsa
          asperiores sit iste, facere laborum beatae cumque quidem esse quos
          pariatur.
          <br />
          <br />
          Lorem ipsum dolor sit amet consectetur adipisicing elit. In libero
          harum corrupti magnam provident? Minus, velit vel. Delectus ipsa
          asperiores sit iste, facere laborum beatae cumque quidem esse quos
          pariatur.
        </h3>
      </div>
    </b-modal>
  </div>
</template>
<script>
export default {
  props: {
    active: Number,
  },
};
</script>
<style lang="scss">
.shop-s1 {
  font-family: "DMSans", Roboto, Arial, sans-serif !important;
  .shop-s1-img {
    background-image: url("/assets/2ndSlideClothes.png");
    background-repeat: no-repeat;
    background-position: right;
    background-size: cover;
    z-index: -2;
    animation-name: animationOfLeft-shop-s1;
    animation-duration: 2s;
  }
  .shop-s1-text {
    height: 100%;
    display: flex;
    text-align: start;
    vertical-align: bottom;
    align-items: center;
    padding-top: 27.5%;
    z-index: 1;
    animation-name: animationOfLeftText-shop-s1;
    animation-duration: 2s;

    h1 {
      transform: translateX(-17.5vw);
      z-index: 1;
      div {
        font-size: 3.5vw;
        font-weight: 800;
        width: 200%;
        color: black;
        span {
          position: relative;
        }
        span:nth-child(2) {
          color: white;
        }
        span:nth-child(1):before {
          content: "";
          position: absolute;
          top: 0;
          left: -25%;
          height: 60%;
          background-color: #dc4510;
          border-radius: 1000px;
          width: 150%;
          z-index: -1;
          animation-name: animationOfLeftSpan1-shop-s1;
          animation-duration: 2.2s;
        }
        span:nth-child(2):before {
          content: "";
          position: absolute;
          top: 20%;
          left: -15%;
          height: 70%;
          background-color: #e58d21;
          border-radius: 1000px;
          width: 120%;
          z-index: -1;
          animation-name: animationOfLeftSpan2-shop-s1;
          animation-duration: 2.2s;
        }
      }
      div::before {
        content: "";
        position: absolute;
        background-image: url("/assets/2ndSlideDraw.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        transform: translateX(200%) translateY(-60%);
        width: 17vw;
        height: 15vh;
        z-index: -1;
        animation-name: animationOpacityImg;
        animation-duration: 2.5s;
      }
    }
  }

  .text-description {
    font-size: 1.25vw;
    font-weight: 600;
    min-width: 45vw;
    transform: translateX(-7.5vw);
    animation-name: animationOfLeftDesc-shop-s1;
    animation-duration: 2s;
  }

  .button-modal {
    margin-top: 5%;
    transform: translateX(-7.5vw);
    animation-name: animationOfLeftDesc-shop-s1;
    animation-duration: 2s;
    .btn-outline-primary {
      background-color: transparent !important;
      border-color: #dc4510cc !important;
      color: #dc4510cc !important;
      font-weight: normal !important;
      font-size: 1.5vw !important;
      border-width: 2px !important;
    }
  }

  @keyframes animationOfLeft-shop-s1 {
    from {
      transform: translateX(-100vw);
    }
    to {
      transform: translateX(0vw);
    }
  }

  @keyframes animationOfLeftDesc-shop-s1 {
    from {
      transform: translateX(100vw);
    }
    to {
      transform: translateX(-7.5vw);
    }
  }

  @keyframes animationOfLeftText-shop-s1 {
    from {
      transform: translateX(100vw);
    }
    to {
      transform: translateX(0vw);
    }
  }

  @keyframes animationOfLeftSpan1-shop-s1 {
    from {
      transform: translateX(100vw) translateY(-50vh);
    }
    to {
      transform: translateX(0vw) translateY(0vh);
    }
  }

  @keyframes animationOfLeftSpan2-shop-s1 {
    from {
      transform: translateX(100vw) translateY(50vh);
    }
    to {
      transform: translateX(0vw) translateY(0vh);
    }
  }
}

.es {
  .shop-s1 {
    h1 {
      div {
        font-size: 3.2vw;
      }
      div::before {
        top: -30%;
      }
    }
  }
}

.body-modal-need {
  padding: 5%;
  .close-btn {
    position: absolute;
    right: 2.5%;
    top: 5%;
  }
}
</style>