<template>
  <!-- SLIDE 3 -->
  <div class="section us-s2">
    <transition name="slide-fades">
      <div class="container-fluid h-100" v-if="active >= 1">
        <div class="row align-items-start h-100">
          <div class="movil-text col-5 h-100">
            <div class="container h-100">
              <div class="row align-items-end h-75">
                <div class="col us-s2-text">
                  <h1 class="ml-1">
                    <div v-html="$t('us_s2')"></div>
                  </h1>
                </div>
              </div>
              <div class="row align-items-start h-25">
                <div class="col"></div>
                <div
                  class="col-10 text-left text-muted text-description"
                  v-html="$t('us_s2_desc')"
                ></div>
                <div class="col"></div>
              </div>
            </div>
          </div>
          <div class="col-7 h-100">
            <div class="movil-img row align-items-start h-100 us-s2-img"></div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  props: {
    active: Number,
  },
};
</script>
<style lang="scss">
.us-s2 {
  font-family: "DMSans", Roboto, Arial, sans-serif !important;
  .us-s2-img {
    background-image: url("/assets/2ndSlideMission.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: 1;
    animation-name: animationOfRight-us-s2;
    animation-duration: 2s;
  }
  .us-s2-text {
    height: 100%;
    display: flex;
    text-align: start;
    vertical-align: bottom;
    align-items: center;
    padding-top: 30%;
    z-index: 1;
    animation-name: animationOfLeftText-us-s2;
    animation-duration: 2s;

    h1 {
      transform: translateX(5vw);
      z-index: 1;
      div {
        font-size: 3.5vw;
        font-weight: 800;
        color: black;
        width: 60vw;
        span {
          position: relative;
        }
        span:nth-child(1) {
          color: white;
        }
        span:nth-child(1):before {
          content: "";
          position: absolute;
          top: 10%;
          left: -5%;
          height: 70%;
          background-color: #dc4510;
          border-radius: 1000px;
          width: 120%;
          z-index: -1;
          animation-name: animationOfLeftSpan2-us-s2;
          animation-duration: 2.2s;
        }
        span:nth-child(3):before {
          content: "";
          position: absolute;
          top: 10%;
          left: -5%;
          height: 70%;
          background-color: #e58d21;
          border-radius: 1000px;
          width: 120%;
          z-index: -1;
          animation-name: animationOfLeftSpan1-us-s2;
          animation-duration: 2.2s;
        }
      }
    }
  }

  .text-description {
    font-size: 1.25vw;
    font-weight: 600;
    transform: translateX(2.5vw) translateY(-80%);
    z-index: 1;
    animation-name: animationOfLeftDesc-us-s2;
    animation-duration: 2s;
  }

  @keyframes animationOfRight-us-s2 {
    from {
      transform: translateX(100vw);
    }
    to {
      transform: translateX(0vw);
    }
  }

  @keyframes animationOfLeftDesc-us-s2 {
    from {
      transform: translateX(-100vw);
    }
    to {
      transform: translateX(2.5vw) translateY(-80%);
    }
  }

  @keyframes animationOfLeftText-us-s2 {
    from {
      transform: translateX(-100vw);
    }
    to {
      transform: translateX(0vw);
    }
  }

  @keyframes animationOfLeftSpan1-us-s2 {
    from {
      transform: translateX(-100vw) translateY(-50vh);
    }
    to {
      transform: translateX(0vw) translateY(0vh);
    }
  }

  @keyframes animationOfLeftSpan2-us-s2 {
    from {
      transform: translateX(-100vw) translateY(50vh);
    }
    to {
      transform: translateX(0vw) translateY(0vh);
    }
  }
}

.es{
  .us-s2{
    h1{
      div{
        
      }
    }
  }
}
</style>