<template>
  <!-- SLIDE 2 -->
  <div class="section park-s1">
    <transition name="slide-fades">
      <div class="container-fluid h-100 col-pfu" v-if="active >= 2">
        <div class="row" style="height: 20%">
          <div class="col"></div>
        </div>
        <div class="row align-items-center" style="height: 80%">
          <div class="col-fu col-sm-1 col-md-1 col-lg-1"></div>
          <div
            class="col-fu col-sm-5 col-md-5 col-lg-5 h-100 park-s1-img"
          ></div>
          <div class="col-fu col-sm-6 col-md-6 col-lg-6 h-100">
            <div class="container h-100">
              <div class="row align-items-end h-50">
                <div class="col park-s1-text">
                  <h1 class="ml-1">
                    <div v-html="$t('park_s1')"></div>
                  </h1>
                </div>
              </div>
              <div class="row align-items-start h-50">
                <div class="col-9">
                  <div
                    class="text-left text-muted text-description"
                    v-html="$t('park_s1_desc')"
                  ></div>
                  <div
                    class="row h-25 align-items-start button-modal"
                    style="margin-left: 0 !important"
                  >
                    <b-button
                      pill
                      variant="outline-primary"
                      v-b-modal.modal-video-tap
                    >
                      {{ $t("readMore") }}</b-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <b-modal
      id="modal-video-tap"
      size="xl"
      centered
      :hide-footer="true"
      :hide-header="true"
      class="video"
    >
      <div class="d-block text-center body-modal-more">
        <b-button
          variant="light"
          size="sm"
          class="mb-2 close-btn"
          @click="$bvModal.hide('modal-video-tap')"
        >
          <b-icon icon="x" aria-hidden="true"></b-icon>
        </b-button>
        <h3>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae
          reprehenderit autem voluptatum est blanditiis debitis. Eligendi, rerum
          unde quae numquam veritatis dolores eius sequi vero nobis,
          exercitationem voluptate, cupiditate ratione.
          <br />
          <br />
          Lorem ipsum dolor sit amet consectetur adipisicing elit. In libero
          harum corrupti magnam provident? Minus, velit vel. Delectus ipsa
          asperiores sit iste, facere laborum beatae cumque quidem esse quos
          pariatur.
          <br />
          <br />
          Lorem ipsum dolor sit amet consectetur adipisicing elit. In libero
          harum corrupti magnam provident? Minus, velit vel. Delectus ipsa
          asperiores sit iste, facere laborum beatae cumque quidem esse quos
          pariatur.
        </h3>
      </div>
    </b-modal>
  </div>
</template>
<script>
export default {
  props: {
    active: Number,
  },
};
</script>
<style lang="scss">
.park-s1 {
  font-family: "DMSans", Roboto, Arial, sans-serif !important;
  .park-s1-img {
    background-image: url("/assets/2dSlideMini.png");
    background-repeat: no-repeat;
    background-position: right top;
    background-size: cover;
    z-index: -2;
    animation-name: animationOfBottom;
    animation-duration: 2s;
  }
  .park-s1-text {
    height: 100%;
    display: flex;
    text-align: start;
    vertical-align: bottom;
    align-items: center;
    padding-top: 27.5%;
    z-index: 1;
    animation-name: animationOfBottom;
    animation-duration: 1.8s;

    h1 {
      transform: translateX(-8.5vw);
      z-index: 1;
      div {
        font-size: 3.5vw;
        font-weight: 800;
        color: black;
        span {
          position: relative;
        }
        span::before {
          content: "";
          position: absolute;
          top: 32.5%;
          left: -17.5%;
          height: 70%;
          background-color: #dc4510;
          border-radius: 1000px;
          animation-name: animationOfBottom;
          animation-duration: 2.2s;
          width: 120%;
          z-index: -1;
        }
      }
      div::after {
        content: "";
        position: absolute;
        background-image: url("/assets/2ndSlideQR.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        transform: translateX(-21%) translateY(-57.5%);
        width: 25vw;
        height: 15vh;
        z-index: -1;
        animation-name: animationOpacityImg;
        animation-duration: 2.5s;
      }
    }
  }

  .text-description {
    font-size: 1.25vw;
    font-weight: 600;
    animation-name: animationOfRightText-park;
    animation-duration: 2s;
  }

  .button-modal {
    margin-top: 5%;
    animation-name: animationOfRightText-park;
    animation-duration: 2s;
    .btn-outline-primary {
      background-color: transparent !important;
      border-color: #dc4510cc !important;
      color: #dc4510cc !important;
      font-weight: normal !important;
      font-size: 1.5vw !important;
      border-width: 2px !important;
    }
  }

  @keyframes animationOfRightText-park {
    from {
      transform: translateX(100vw);
    }
    to {
      transform: translateX(0vw);
    }
  }
}

.es {
  .park-s1 {
    h1 {
      div {
        width: 50%;
        transform: translateX(40%);
      }
    }
  }
}

.body-modal-more {
  padding: 5%;
  .close-btn {
    position: absolute;
    right: 2.5%;
    top: 5%;
  }
}
</style>