<template>
  <!-- SLIDE 4 -->
  <div class="section park-effortlessly">
    <transition name="slide-fade">
      <div class="container-fluid h-100" v-if="active >= 3">
        <div class="row align-items-center h-100 col-pfu">
          <div class="col-fu col-sm-9 col-md-9 col-lg-9 h-100 park-img"></div>
          <div class="col-fu col-sm-3 col-md-3 col-lg-3 h-100 h-100">
            <div class="container h-100">
              <div class="row align-items-end h-50">
                <div class="col park-text">
                  <h1 class="ml-1">
                    <div v-html="$t('park_effortlessly')"></div>
                  </h1>
                </div>
              </div>
              <div class="row align-items-start h-50">
                <div class="col-9">
                  <div
                    class="text-left text-muted text-description"
                    v-html="$t('homePark')"
                  ></div>
                  <div class="row h-25 align-items-start button-modal">
                    <router-link to="/Services?park=true" style="width: 50%">
                      <b-button pill variant="outline-primary">
                        {{ $t("park") }}</b-button
                      ></router-link
                    >
                  </div>
                </div>
                <div class="col-3"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    active: Number,
  },
};
</script>
<style lang="scss">
.park-effortlessly {
  font-family: "DMSans", Roboto, Arial, sans-serif !important;

  .park-img {
    background-image: url("/assets/4rdSlidePark.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: 1;
    animation-name: animate-image;
    animation-duration: 3s;
  }

  .park-text {
    height: 100%;
    display: flex;
    text-align: start;
    vertical-align: bottom;
    align-items: center;
    padding-top: 25%;
    animation-name: animationOfLeft-park;
    animation-duration: 3s;
    z-index: 1;

    h1 {
      transform: translateX(-4vw);
      z-index: 1;
      div {
        font-size: 3.5vw;
        font-weight: 800;
        color: black;
        width: 130%;
        transform: translateX(-20%);
        //text-shadow: 2px 2px 6px grey;
        span {
          position: relative;
          color: white;
          //text-shadow: -2px -2px 4px black;
        }
        span::before {
          content: "";
          position: absolute;
          background-color: #da2204;
          top: 0;
          left: -22.5%;
          height: 70%;
          border-radius: 50px;
          width: 145%;
          z-index: -1;
          animation-name: animationOfLeft-park;
          animation-duration: 1.875s;
        }
      }
    }
  }

  .text-description {
    font-size: 1vw;
    font-weight: 600;
    animation-name: animationOfBottom-park;
    animation-duration: 3s;
  }

  .button-modal {
    margin-top: 5%;
    margin-left: 0 !important;
    animation-name: animationOfLeft-park;
    animation-duration: 3s;
    .btn-outline-primary {
      background-color: transparent !important;
      border-color: #dc4510cc !important;
      color: #dc4510cc !important;
      font-weight: normal !important;
      font-size: 2vw !important;
      border-width: 2px !important;
      position: absolute !important;
      left: 0 !important;
      margin-left: 15px !important;
      // width: 100% !important;
    }
  }
}

.es {
  .park-effortlessly {
    h1 {
      transform: translateX(-4vw);
      z-index: 1;
      div {
        text-align: right;
        transform: translateX(-35%);
        span::before {
          background-color: rgba(220, 30, 16, 0.8);
          top: 30%;
          left: 10%;
          height: 70%;
          border-radius: 50px;
          width: 95%;
        }
      }
    }
  }
}

@keyframes animate-image {
  from {
    transform: translateX(-100vh) translateY(100vh);
  }
  to {
    transform: translateX(0vh) translateY(0vh);
  }
}

@keyframes animationOfBottom-park {
  from {
    transform: translateY(100vh);
  }
  to {
    transform: translateY(0vh);
  }
}

@keyframes animationOfLeft-park {
  from {
    transform: translateX(-100vw);
  }
  to {
    transform: translateX(0vw);
  }
}
</style>