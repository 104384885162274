import i18n from "./i18n";
import Vue from "vue";
import Vuex from "vuex";
import StoreData from "./store";
Vue.use(StoreData);
Vue.use(Vuex);
Vue.use(i18n);

const store = new Vuex.Store(StoreData);
export default {
  changeLanguage(language) {
    i18n.locale = language;
    store.state.language = language;
    localStorage.setItem("language", language);
  },
}