<template>
  <!-- SLIDE 3 -->
  <div class="section plazas-s2">
    <transition name="slide-fades">
      <div class="container-fluid h-100" v-if="active >= 1">
        <div class="row align-items-start h-100">
          <div class="movil-text col-7 h-100">
            <div class="container h-100">
              <div class="row align-items-end h-75">
                <div class="col plazas-s2-text">
                  <h1 class="ml-1">
                    <div v-html="$t('plazas_s2')"></div>
                  </h1>
                </div>
              </div>
              <div class="row align-items-start h-25">
                <div class="col"></div>
                <div class="col-10">
                  <div
                    class="text-left text-muted text-description"
                    v-html="$t('plazas_s2_desc')"
                  ></div>
                  <div class="row h-25 align-items-start button-modal">
                    <b-button
                      pill
                      variant="outline-primary"
                      v-b-modal.modal-video-change_nedd
                    >
                      {{ $t("readMore") }}</b-button
                    >
                  </div>
                </div>
                <div class="col"></div>
              </div>
            </div>
          </div>
          <div class="movil-img col-5 h-100">
            <div class="row align-items-start h-100 plazas-s2-img"></div>
          </div>
        </div>
      </div>
    </transition>
    <b-modal
      id="modal-video-change_nedd"
      size="xl"
      centered
      :hide-footer="true"
      :hide-header="true"
      class="video"
    >
      <div class="d-block text-center body-modal-change_nedd">
        <b-button
          variant="light"
          size="sm"
          class="mb-2 close-btn"
          @click="$bvModal.hide('modal-video-change_nedd')"
        >
          <b-icon icon="x" aria-hidden="true"></b-icon>
        </b-button>
        <b-carousel
          id="carousel-1"
          v-model="slide"
          :interval="4000"
          background="#ababab"
          img-width="1024"
          img-height="480"
          style="text-shadow: 1px 1px 2px #333"
        >
          <!-- Text slides with image -->
          <b-carousel-slide
            caption="FetchUp"
            text="Nulla vitae elit libero, a pharetra augue mollis interdum."
            img-src="https://picsum.photos/1024/480/?image=52"
          ></b-carousel-slide>
        </b-carousel>
        <br />
        <h3>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae
          reprehenderit autem voluptatum est blanditiis debitis. Eligendi, rerum
          unde quae numquam veritatis dolores eius sequi vero nobis,
          exercitationem voluptate, cupiditate ratione.
        </h3>
      </div>
    </b-modal>
  </div>
</template>
<script>
export default {
  props: {
    active: Number,
  },
  data() {
    return {
      slide: 0,
      sliding: null,
    };
  },
};
</script>
<style lang="scss">
.plazas-s2 {
  font-family: "DMSans", Roboto, Arial, sans-serif !important;
  .plazas-s2-img {
    background-image: url("/assets/2ndSlideParkMall.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: 1;
    animation-name: animationOfRight-plazas-s2;
    animation-duration: 2s;
  }
  .plazas-s2-text {
    height: 100%;
    display: flex;
    text-align: start;
    vertical-align: bottom;
    align-items: center;
    padding-top: 30%;
    z-index: 1;
    animation-name: animationOfLeftText-plazas-s2;
    animation-duration: 2s;

    h1 {
      transform: translateX(12.5vw);
      z-index: 1;
      div {
        font-size: 3.5vw;
        font-weight: 800;
        color: black;
        width: 60vw;
        span {
          position: relative;
        }
        span:nth-child(1) {
          color: white;
        }
        span:nth-child(1):before {
          content: "";
          position: absolute;
          top: 10%;
          left: -5%;
          height: 70%;
          background-color: #dc4510;
          border-radius: 1000px;
          width: 120%;
          z-index: -1;
          animation-name: animationOfLeftSpan2-plazas-s2;
          animation-duration: 2.2s;
        }
        span:nth-child(3):before {
          content: "";
          position: absolute;
          top: 10%;
          left: -5%;
          height: 70%;
          background-color: #e58d21;
          border-radius: 1000px;
          width: 120%;
          z-index: -1;
          animation-name: animationOfLeftSpan1-plazas-s2;
          animation-duration: 2.2s;
        }
      }
      div::before {
        content: "";
        position: absolute;
        background-image: url("/assets/3rdSlidePie.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        transform: translateX(115%) translateY(-80%) rotateY(180deg);
        width: 25vw;
        height: 22.5vh;
        z-index: -1;
        animation-name: animationOpacityImg;
        animation-duration: 2.5s;
      }
    }
  }

  .text-description {
    font-size: 1.25vw;
    font-weight: 600;
    transform: translateX(17.5%) translateY(-100%);
    z-index: 1;
    animation-name: animationOfLeftDesc-plazas-s2;
    animation-duration: 2s;
  }

  .button-modal {
    width: 30%;
    margin-left: 0px !important;
    margin-top: 5%;
    transform: translateX(57.5%) translateY(-100%);
    animation-name: animationOfLeftDesc-plazas-s2-img;
    animation-duration: 2s;
    .btn-outline-primary {
      background-color: transparent !important;
      border-color: #dc4510cc !important;
      color: #dc4510cc !important;
      font-weight: normal !important;
      font-size: 1.5vw !important;
      border-width: 2px !important;
      width: 100% !important;
    }
  }

  @keyframes animationOfRight-plazas-s2 {
    from {
      transform: translateX(100vw);
    }
    to {
      transform: translateX(0vw);
    }
  }

  @keyframes animationOfLeftDesc-plazas-s2 {
    from {
      transform: translateX(-100vw);
    }
    to {
      transform: translateX(17.5%) translateY(-100%);
    }
  }

  @keyframes animationOfLeftDesc-plazas-s2-img {
    from {
      transform: translateX(-100vw);
    }
    to {
      transform: translateX(57.5%) translateY(-100%);
    }
  }

  @keyframes animationOfLeftText-plazas-s2 {
    from {
      transform: translateX(-100vw);
    }
    to {
      transform: translateX(0vw);
    }
  }

  @keyframes animationOfLeftSpan1-plazas-s2 {
    from {
      transform: translateX(-100vw) translateY(-50vh);
    }
    to {
      transform: translateX(0vw) translateY(0vh);
    }
  }

  @keyframes animationOfLeftSpan2-plazas-s2 {
    from {
      transform: translateX(-100vw) translateY(50vh);
    }
    to {
      transform: translateX(0vw) translateY(0vh);
    }
  }
}

.es {
  .plazas-s2 {
    h1 {
      div {
        width: 100%;
      }
    }
  }
}

.body-modal-change_nedd {
  padding: 5%;
  .close-btn {
    position: absolute;
    right: 0.5%;
    top: 0.5%;
  }
}
</style>