<template>
  <!-- SLIDE 2 -->
  <div class="section store-manager-assis">
    <transition name="slide-fades">
      <div class="container-fluid h-100" v-if="active >= 2">
        <div class="row" style="height: 5%">
          <div class="col"></div>
        </div>
        <div class="row align-items-center" style="height: 95%">
          <div class="movil-text col-1 h-100">
            <div class="container h-100">
              <div class="row align-items-end h-50">
                <div class="col-12 store-manager-assis-text">
                  <h1 class="ml-1">
                    <div v-html="$t('stores_manager_assis')"></div>
                  </h1>
                </div>
              </div>
              <div class="row align-items-start h-50">
                <div class="col-12">
                  <div
                    class="text-left text-muted text-description"
                    v-html="$t('stores_manager_assis_desc')"
                  ></div>
                  <div class="row h-25 align-items-start button-modal">
                    <b-button
                      pill
                      variant="outline-primary"
                      v-b-modal.modal-video-assistent
                    >
                      {{ $t("readMore") }}</b-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="movil-img col-5 h-100 store-manager-assis-img"></div>
          <div class="col-6 h-100"></div>
        </div>
      </div>
    </transition>
    <b-modal
      id="modal-video-assistent"
      size="xl"
      centered
      :hide-footer="true"
      :hide-header="true"
      class="video"
    >
      <div class="d-block text-center body-modal-assistent">
        <b-button
          variant="light"
          size="sm"
          class="mb-2 close-btn"
          @click="$bvModal.hide('modal-video-assistent')"
        >
          <b-icon icon="x" aria-hidden="true"></b-icon>
        </b-button>
        <b-carousel
          id="carousel-1"
          v-model="slide"
          :interval="4000"
          controls
          indicators
          background="#ababab"
          img-width="1024"
          img-height="480"
          style="text-shadow: 1px 1px 2px #333"
        >
          <!-- Text slides with image -->
          <b-carousel-slide
            caption="FetchUp"
            text="Nulla vitae elit libero, a pharetra augue mollis interdum."
            img-src="https://picsum.photos/1024/480/?image=52"
          ></b-carousel-slide>

          <!-- Slides with custom text -->
          <b-carousel-slide img-src="https://picsum.photos/1024/480/?image=54">
            <h1>FetchUp</h1>
          </b-carousel-slide>

          <!-- Slides with custom text -->
          <b-carousel-slide img-src="https://picsum.photos/1024/480/?image=54">
            <h1>FetchUp</h1>
          </b-carousel-slide>
        </b-carousel>
        <br />
        <h3>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae
          reprehenderit autem voluptatum est blanditiis debitis. Eligendi, rerum
          unde quae numquam veritatis dolores eius sequi vero nobis,
          exercitationem voluptate, cupiditate ratione.
        </h3>
      </div>
    </b-modal>
  </div>
</template>
<script>
export default {
  props: {
    active: Number,
  },
  data() {
    return {
      slide: 0,
      sliding: null,
    };
  },
};
</script>
<style lang="scss">
.store-manager-assis {
  font-family: "DMSans", Roboto, Arial, sans-serif !important;
  .store-manager-assis-img {
    background-image: url("/assets/3rdSlideManager.png");
    background-repeat: no-repeat;
    background-position: right center;
    background-size: contain;
    z-index: -2;
    transform: translateX(25vw);
    animation-name: animationOfTop-manager;
    animation-duration: 2s;
  }
  .store-manager-assis-text {
    height: 100%;
    display: flex;
    text-align: start;
    vertical-align: bottom;
    align-items: center;
    transform: translateY(35vh);
    z-index: 1;
    animation-name: animation-manager-assis-text;
    animation-duration: 1.8s;

    h1 {
      transform: translateX(2.5vw);
      z-index: 1;
      div {
        font-size: 3.5vw;
        font-weight: 800;
        color: black;
        width: 60vw;
        span {
          position: relative;
        }
        span:nth-child(1) {
          color: white;
        }
        span:nth-child(1):before {
          content: "";
          position: absolute;
          top: 17.5%;
          left: -5%;
          height: 70%;
          background-color: #e58d21;
          border-radius: 1000px;
          width: 110%;
          z-index: -1;
          animation-name: animation-manager-assis-text-span;
          animation-duration: 2.2s;
        }
        span:before {
          content: "";
          position: absolute;
          top: 40%;
          left: -10%;
          height: 60%;
          background-color: #dc4510;
          border-radius: 1000px;
          width: 110%;
          z-index: -1;
          animation-name: animation-manager-assis-text-span2;
          animation-duration: 2.2s;
        }
      }
      div::after {
        content: "";
        position: absolute;
        background-image: url("/assets/2ndSlideDraw.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        transform: translateX(-140%) translateY(-130%);
        width: 20vw;
        height: 15vh;
        z-index: -1;
        animation-name: animationOpacityImg;
        animation-duration: 2.5s;
      }
    }
  }

  .text-description {
    font-size: 1.25vw;
    font-weight: 600;
    min-width: 40vw;
    transform: translateY(22.5vh) translateX(2.5vw);
    animation-name: animationOfRightText-manager-assis;
    animation-duration: 2s;
  }

  .button-modal {
    width: 200%;
    margin-left: 0px !important;
    margin-top: 5%;
    transform: translateY(22.5vh) translateX(2.5vw);
    animation-name: animationOfRightText-manager-assis;
    animation-duration: 2s;
    .btn-outline-primary {
      background-color: transparent !important;
      border-color: #dc4510cc !important;
      color: #dc4510cc !important;
      font-weight: normal !important;
      font-size: 1.5vw !important;
      border-width: 2px !important;
      width: 100% !important;
    }
  }

  @keyframes animationOfTop-manager {
    from {
      background-image: url("/assets/2ndSlideAdmin.png");
      transform: translateY(-100vh) translateX(0vw);
    }
    to {
      background-image: url("/assets/3rdSlideManager.png");
      transform: translateY(0vh) translateX(25vw);
    }
  }

  @keyframes animationOfRightText-manager-assis {
    from {
      transform: translateX(-100vw) translateY(0vh);
    }
    to {
      transform: translateX(2.5vw) translateY(22.5vh);
    }
  }

  @keyframes animation-manager-assis-text {
    from {
      transform: translateY(-100vh) translateX(-100vw);
    }
    to {
      transform: translateY(35vh) translateX(0vw);
    }
  }

  @keyframes animation-manager-assis-text-span {
    from {
      transform: translateY(200vh) translateX(-200vw);
    }
    to {
      transform: translateY(0vh) translateX(0vw);
    }
  }

  @keyframes animation-manager-assis-text-span2 {
    from {
      transform: translateY(-200vh) translateX(-200vw);
    }
    to {
      transform: translateY(0vh) translateX(0vw);
    }
  }
}

.es {
  .store-manager-assis {
    h1 {
      div {
        width: 29vw;
      }
      span:nth-child(1) {
        //text-shadow: -2px -2px black;
      }
      div::after {
        transform: translateX(-90%) translateY(-130%);
      }
    }
  }
}

.body-modal-assistent {
  padding: 5%;
  .close-btn {
    position: absolute;
    right: 0.5%;
    top: 0.5%;
  }
}
</style>