<template>
  <!-- SLIDE 5 -->
  <div class="section download" id="slide5">
    <transition name="slide-fade">
      <div class="container-fluid h-100" v-if="active >= 4">
        <div class="row align-items-center h-100 col-pfu">
          <div class="col-fu col-sm-5 col-md-5 col-lg-5 h-100">
            <div class="container h-100">
              <div class="row align-items-end h-50">
                <div class="col download-text">
                  <h1 class="ml-1">
                    <div>
                      {{ $t("download") }} <span>Fetch<span>Up</span></span
                      ><img
                        class="mb-3 ml-2"
                        src="/assets/4rdSlideSqueriel.png"
                      />
                    </div>
                  </h1>
                  <div>{{ $t("available") }}</div>
                </div>
              </div>
              <div class="row align-items-start h-50">
                <div class="col">
                  <div class="container mt-5">
                    <div class="row h-25 stores">
                      <div class="col">
                        <a
                          target="blanck"
                          href="https://apps.apple.com/mx/app/fetchup/id1529489977"
                        >
                          <img src="/assets/logoBlackAppStore.svg" />
                        </a>
                      </div>
                      <div class="col">
                        <a
                          target="blanck"
                          href="https://play.google.com/store/apps/details?id=com.smartcity.fetchup.mx"
                        >
                          <img src="/assets/logoBlackGooglePlay.svg"
                        /></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-fu col-sm-7 col-md-7 col-lg-7 h-100 download-img"
          ></div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    active: Number,
  },
};
</script>
<style lang="scss">
.download {
  font-family: "DMSans", Roboto, Arial, sans-serif !important;

  .download-img {
    background-image: url("/assets/4rdSlideJumping.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: -1;
    animation-name: animate-image-dw;
    animation-duration: 3s;
  }

  .download-text {
    height: 100%;
    text-align: start;
    align-items: center;
    padding-top: 40%;
    animation-name: animationOfLeft-download;
    animation-duration: 3s;
    z-index: 1;

    h1 {
      transform: translateX(21.5vw);
      z-index: 9999;
      div {
        font-size: 3.5vw;
        font-weight: 900;
        color: black;
        width: 120%;
        transform: translateX(-15.5%);
        //text-shadow: 2px 2px 6px grey;
        span {
          position: relative;
          span {
            color: white;
            //text-shadow: -2px -2px 4px black;
          }
        }
        span::before {
          content: "";
          position: absolute;
          background-color: #da2204;
          top: 5%;
          left: 0;
          height: 70%;
          border-radius: 50px;
          width: 145%;
          transform: translateX(-3%);
          z-index: -1;
          animation-name: animationOfLeftSpan-download;
          animation-duration: 2.25s;
        }
        img {
          height: 2.5vw;
        }
      }
      div::after {
        content: "";
        position: absolute;
        background-image: url("/assets/2ndSlideDraw.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        transform: translateX(-325%) translateY(-50%);
        width: 14.5vw;
        height: 10vw;
        z-index: -2;
        animation-name: animationOpacityImg;
        animation-duration: 3s;
      }
    }
    div {
      font-size: 1vw;
      font-weight: 900;
      color: gray;
      width: 120%;
      transform: translateX(32%) translateY(-30%);
    }
  }

  .stores {
    position: absolute;
    bottom: 0;
    right: 0;
    img {
      width: 12.5vw;
    }
    animation-name: animationOfBottom-download;
    animation-duration: 3s;
  }
}

.es {
  .download {
    h1 {
      div {
        content: "";
      }
    }
  }
}

@keyframes animate-image-dw {
  from {
    transform: translateX(100vh) translateY(100vh);
  }
  to {
    transform: translateX(0vh) translateY(0vh);
  }
}

@keyframes animationOfBottom-download {
  from {
    transform: translateY(100vh);
  }
  to {
    transform: translateY(0vh);
  }
}

@keyframes animationOfLeft-download {
  from {
    transform: translateX(-100vw);
  }
  to {
    transform: translateX(0vw);
  }
}

@keyframes animationOfLeftSpan-download {
  from {
    transform: translateX(-100vw);
  }
  to {
    transform: translateX(-3%);
  }
}
</style>