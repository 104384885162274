<template>
  <!-- SLIDE 3 -->
  <div class="section park-s2">
    <transition name="slide-fades">
      <div class="container-fluid h-100 col-pfu" v-if="active >= 1">
        <div class="row align-items-start h-100">
          <div class="col-fu col-6 h-100">
            <div class="container h-100">
              <div class="row align-items-end h-75">
                <div class="col park-s2-text">
                  <h1 class="ml-1">
                    <div v-html="$t('park_s2')"></div>
                  </h1>
                </div>
              </div>
              <div class="row align-items-start h-25">
                <div class="col"></div>
                <div class="col-10">
                  <div
                    class="text-left text-muted text-description"
                    v-html="$t('park_s2_desc')"
                  ></div>
                  <div class="row h-25 align-items-start button-modal">
                    <b-button
                      pill
                      variant="outline-primary"
                      v-b-modal.modal-video-easy
                    >
                      <b-icon-play-fill></b-icon-play-fill>
                      {{ $t("homeWatchVideo") }}</b-button
                    >
                  </div>
                </div>
                <div class="col"></div>
              </div>
            </div>
          </div>
          <div class="col-fu col-4 h-100">
            <div class="col h-100">
              <div class="container h-100">
                <div class="row align-items-end h-25"></div>
                <div class="row align-items-start h-75 park-s2-img"></div>
              </div>
            </div>
          </div>
          <div class="col-fu col-2 h-100"></div>
        </div>
      </div>
    </transition>
    <b-modal
      id="modal-video-easy"
      size="xl"
      centered
      :hide-footer="true"
      :hide-header="true"
      class="video"
    >
      <b-embed
        type="iframe"
        aspect="16by9"
        src="https://www.youtube.com/embed/EOMXXJJeDiY?autoplay=1"
        crossorigin="anonymous"
        autoplay
        allowfullscreen
      ></b-embed>
    </b-modal>
  </div>
</template>
<script>
export default {
  props: {
    active: Number,
  },
};
</script>
<style lang="scss">
.park-s2 {
  font-family: "DMSans", Roboto, Arial, sans-serif !important;
  .park-s2-img {
    background-image: url("/assets/3rdSlideScan.png");
    background-repeat: no-repeat;
    background-position: left;
    background-size: contain;
    transform: translateY(-7.5vh) translateX(7.5vh);
    z-index: 1;
    animation-name: animationOfRight;
    animation-duration: 2s;
  }
  .park-s2-text {
    height: 100%;
    display: flex;
    text-align: start;
    vertical-align: bottom;
    align-items: center;
    padding-top: 30%;
    z-index: 1;
    animation-name: animationOfLeftText;
    animation-duration: 2s;

    h1 {
      transform: translateX(25.5vw);
      z-index: 1;
      div {
        font-size: 3.5vw;
        font-weight: 800;
        color: black;
        span {
          position: relative;
        }
        span:nth-child(1):before {
          content: "";
          position: absolute;
          top: 0;
          left: -25%;
          height: 60%;
          background-color: #e58d21;
          border-radius: 1000px;
          width: 150%;
          z-index: -1;
          animation-name: animationOfLeftSpan1;
          animation-duration: 2.2s;
        }
        span:nth-child(2):before {
          content: "";
          position: absolute;
          top: 32.5%;
          left: -17.5%;
          height: 70%;
          background-color: #dc4510;
          border-radius: 1000px;
          width: 120%;
          z-index: -1;
          animation-name: animationOfLeftSpan2;
          animation-duration: 2.2s;
        }
        span:nth-child(2) {
          ::before {
            background-color: unset;
          }
          span {
            color: white;
          }
        }
      }
      div::before {
        content: "";
        position: absolute;
        background-image: url("/assets/3rdSlidePie.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        transform: translateX(-62.5%) translateY(-57.5%) rotateY(180deg);
        width: 25vw;
        height: 22.5vh;
        z-index: -1;
        animation-name: animationOpacityImg;
        animation-duration: 2.5s;
      }
    }
  }

  .text-description {
    top: 10%;
    font-size: 1.25vw;
    font-weight: 600;
    transform: translateX(15%) translateY(-270%);
    animation-name: animationOfLeftDesc;
    animation-duration: 2s;
  }

  .button-modal {
    margin-top: 5%;
    transform: translateX(15%) translateY(-270%);
    animation-name: animationOfLeftDesc;
    animation-duration: 2s;
    .btn-outline-primary {
      background-color: transparent !important;
      border-color: #dc4510cc !important;
      color: #dc4510cc !important;
      font-weight: normal !important;
      font-size: 1.5vw !important;
      border-width: 2px !important;
    }
  }

  @keyframes animationOfRight {
    from {
      transform: translateX(100vw);
    }
    to {
      transform: translateY(-7.5vh) translateX(7.5vh);
    }
  }

  @keyframes animationOfLeftDesc {
    from {
      transform: translateX(-100vw);
    }
    to {
      transform: translateX(15%) translateY(-270%);
    }
  }

  @keyframes animationOfLeftText {
    from {
      transform: translateX(-100vw);
    }
    to {
      transform: translateX(0vw);
    }
  }

  @keyframes animationOfLeftSpan1 {
    from {
      transform: translateX(-100vw) translateY(-50vh);
    }
    to {
      transform: translateX(0vw) translateY(0vh);
    }
  }

  @keyframes animationOfLeftSpan2 {
    from {
      transform: translateX(-100vw) translateY(50vh);
    }
    to {
      transform: translateX(0vw) translateY(0vh);
    }
  }
}

.es {
  .park-s2 {
    h1 {
      div {
        width: 50%;
      }
      div::before {
        transform: translateX(-100%) translateY(-57.5%);
      }
    }
    .text-description {
      top: 60%;
      transform: translateX(0%) translateY(-270%);
    }
  }
}
</style>