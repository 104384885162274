<template>
  <div class="stores-screen">
    <full-page ref="fullpage" :options="options">
      <PlugPlay></PlugPlay>
      <PlazasS2 :active="activePage"></PlazasS2>
      <PlazasS3 :active="activePage"></PlazasS3>
      <PlazasS4 :active="activePage"></PlazasS4>
      <Footer></Footer>
    </full-page>
  </div>
</template>
<script>
import PlugPlay from "@/views/Plazas/PlugPlay";
import PlazasS2 from "@/views/Plazas/PlazasS2";
import PlazasS3 from "@/views/Plazas/PlazasS3";
import PlazasS4 from "@/views/Plazas/PlazasS4";
import Footer from "@/views/Footer";
export default {
  components: {
    PlugPlay,
    PlazasS2,
    PlazasS3,
    PlazasS4,
    Footer,
  },
  data() {
    return {
      options: {
        licenseKey: "7FD5911B-F38C4985-A35FC134-394A56DE",
        scrollBar: false,
        navigation: true,
        onLeave: this.onLeaveMeth,
      },
      activePage: 0,
    };
  },
  methods: {
    onLeaveMeth(origin, destination) {
      // function (origin, destination)
      //let o = origin.index || 0;
      let d = destination.index || 0;
      this.activePage = d;
    },
  },
};
</script>
<style>
.slide-fade-enter-active {
  transition: all 0s ease-in;
}
.slide-fade-leave-active {
  transition: all 0.5s ease-out;
}
.slide-fade-leave-to {
  /* transform: translateX(50vw) translateY(-200vh); */
  opacity: 0;
}
</style>
<style lang="scss">
.stores-screen {
  font-family: "DMSans", Roboto, Arial, sans-serif !important;
  .section {
    background-repeat: no-repeat;
  }

  @keyframes animationOfBottom {
    from {
      transform: translateY(100vh);
    }
    to {
      transform: translateY(0vh);
    }
  }

  @keyframes animationOfTop {
    from {
      transform: translateY(-200vh);
    }
    to {
      transform: translateY(0vh);
    }
  }

  @keyframes animationOpacityImg {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    75% {
      opacity: 0.2;
    }
    100% {
      opacity: 1;
    }
  }
}
</style>