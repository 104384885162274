<template>
  <!-- SLIDE 2 -->
  <div class="section store-adminlike">
    <transition name="slide-fades">
      <div class="container-fluid h-100" v-if="active >= 1">
        <div class="row" style="height: 5%">
          <div class="col"></div>
        </div>
        <div class="row align-items-center" style="height: 95%">
          <div class="col"></div>
          <div class="movil-img col-5 h-100 store-adminlike-img"></div>
          <div class="movil-text col-6 h-100">
            <div class="container h-100">
              <div class="row align-items-end h-50">
                <div class="col store-adminlike-text">
                  <h1 class="ml-1">
                    <div v-html="$t('stores_adminlike')"></div>
                  </h1>
                </div>
              </div>
              <div class="row align-items-start h-50">
                <div class="col-9">
                  <div
                    class="text-left text-muted text-description"
                    v-html="$t('stores_adminlike_desc')"
                  ></div>
                  <div class="row h-25 align-items-start button-modal">
                    <b-button
                      pill
                      variant="outline-primary"
                      v-b-modal.modal-video-like
                    >
                      {{ $t("readMore") }}</b-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <b-modal
      id="modal-video-like"
      size="xl"
      centered
      :hide-footer="true"
      :hide-header="true"
      class="video"
    >
      <div class="d-block text-center body-modal-like">
        <b-button
          variant="light"
          size="sm"
          class="mb-2 close-btn"
          @click="$bvModal.hide('modal-video-like')"
        >
          <b-icon icon="x" aria-hidden="true"></b-icon>
        </b-button>
        <b-carousel
          id="carousel-1"
          v-model="slide"
          :interval="4000"
          controls
          indicators
          background="#ababab"
          img-width="1024"
          img-height="480"
          style="text-shadow: 1px 1px 2px #333"
        >
          <!-- Text slides with image -->
          <b-carousel-slide
            caption="FetchUp"
            text="Nulla vitae elit libero, a pharetra augue mollis interdum."
            img-src="https://picsum.photos/1024/480/?image=52"
          ></b-carousel-slide>

          <!-- Slides with custom text -->
          <b-carousel-slide img-src="https://picsum.photos/1024/480/?image=54">
            <h1>FetchUp</h1>
          </b-carousel-slide>

          <!-- Slides with custom text -->
          <b-carousel-slide img-src="https://picsum.photos/1024/480/?image=54">
            <h1>FetchUp</h1>
          </b-carousel-slide>
        </b-carousel>
        <br />
        <h3>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae
          reprehenderit autem voluptatum est blanditiis debitis. Eligendi, rerum
          unde quae numquam veritatis dolores eius sequi vero nobis,
          exercitationem voluptate, cupiditate ratione.
        </h3>
      </div>
    </b-modal>
  </div>
</template>
<script>
export default {
  props: {
    active: Number,
  },
  data() {
    return {
      slide: 0,
      sliding: null,
    };
  },
};
</script>
<style lang="scss">
.store-adminlike {
  font-family: "DMSans", Roboto, Arial, sans-serif !important;
  .store-adminlike-img {
    background-image: url("/assets/2ndSlideAdmin.png");
    background-repeat: no-repeat;
    background-position: right center;
    background-size: contain;
    z-index: -2;
    animation-name: animationOfTop;
    animation-duration: 2s;
  }
  .store-adminlike-text {
    height: 100%;
    display: flex;
    text-align: start;
    vertical-align: bottom;
    align-items: center;
    padding-top: 12.5%;
    z-index: 1;
    animation-name: animation-adminlike-text;
    animation-duration: 1.8s;

    h1 {
      transform: translateX(-8.5vw);
      z-index: 1;
      div {
        font-size: 3.5vw;
        font-weight: 800;
        color: black;
        span {
          position: relative;
          color: white;
        }
        span::before {
          content: "";
          position: absolute;
          top: 10%;
          left: -5%;
          height: 65%;
          background-color: #dc4510;
          border-radius: 1000px;
          animation-name: animation-adminlike-text-span;
          animation-duration: 2.2s;
          width: 125%;
          z-index: -1;
        }
      }
    }
  }

  .text-description {
    font-size: 1.25vw;
    font-weight: 600;
    animation-name: animationOfRightText-adminlike;
    animation-duration: 2s;
  }

  .button-modal {
    width: 30%;
    margin-left: 0px !important;
    width: 30%;
    margin-top: 5%;
    animation-name: animationOfRightText-adminlike;
    animation-duration: 2s;
    .btn-outline-primary {
      background-color: transparent !important;
      border-color: #dc4510cc !important;
      color: #dc4510cc !important;
      font-weight: normal !important;
      font-size: 1.5vw !important;
      border-width: 2px !important;
      width: 100% !important;
    }
  }

  @keyframes animationOfRightText-adminlike {
    from {
      transform: translateX(100vw);
    }
    to {
      transform: translateX(0vw);
    }
  }

  @keyframes animation-adminlike-text {
    from {
      transform: translateY(-100vh) translateX(100vw);
    }
    to {
      transform: translateY(0vh) translateX(0vw);
    }
  }

  @keyframes animation-adminlike-text-span {
    from {
      transform: translateY(200vh) translateX(-200vw);
    }
    to {
      transform: translateY(0vh) translateX(0vw);
    }
  }
}

.es {
  .store-adminlike {
    .store-adminlike-text {
      text-align: center;

      h1 {
        div {
          position: relative;
          width: 43vw;
          transform: translateX(-3%);

          span {
            display: inline-block;
            //text-shadow: -2px -2px black;
          }

          span::before {
            left: -10%;
            background-color: rgba($color: rgba(219, 15, 15, 0.8), $alpha: 1);
            height: 90%;
            top: 10%;
          }
        }
      }
    }
    .text-description {
      top: -15%;
    }
  }
}

.body-modal-like {
  padding: 5%;
  .close-btn {
    position: absolute;
    right: 0.5%;
    top: 0.5%;
  }
}
</style>