<template>
  <b-container fluid class="p-0">
    <b-navbar id="header" toggleable="lg" type="dark" variant="light">
      <b-navbar-toggle target="nav-collapse" disabled>
        <b-icon
          icon="justify"
          variant="dark"
          v-b-toggle.sidebar-no-header
        ></b-icon>
      </b-navbar-toggle>
      <b-navbar-brand class="p-0">
        <router-link to="/" class="nav-link">
          <img v-bind:src="'/assets/logoFetchUpHeader.png'" />
        </router-link>
      </b-navbar-brand>

      <!-- Right aligned nav items -->
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-item>
            <router-link to="/Services" class="nav-link services">{{
              $t("headerServices")
            }}</router-link>
          </b-nav-item>
          <b-nav-item>
            <router-link to="/Stores" class="nav-link stores">{{
              $t("headerStores")
            }}</router-link>
          </b-nav-item>
          <b-nav-item>
            <router-link to="/Plazas" class="nav-link plazas">{{
              $t("headerPlazas")
            }}</router-link>
          </b-nav-item>
          <b-nav-item>
            <router-link to="/Us" class="nav-link us">{{
              $t("headerUs")
            }}</router-link>
          </b-nav-item>
          <b-dropdown variant="default">
            <template #button-content>
              <b-icon icon="globe" aria-hidden="false"></b-icon>
            </template>
            <b-dropdown-item-button @click="$helpers.changeLanguage('es')">
              {{ $t("es") }}
            </b-dropdown-item-button>
            <b-dropdown-item-button @click="$helpers.changeLanguage('en')">
              {{ $t("en") }}
            </b-dropdown-item-button>
          </b-dropdown>
          <b-dropdown variant="default" class="login-btn" right>
            <template #button-content> {{ $t("login") }} </template>
            <b-dropdown-item-button
              ><b-link href="https://shopping.fetchup.io" target="_black">
                Shopping
              </b-link></b-dropdown-item-button
            >
            <b-dropdown-item-button>
              <b-link href="https://parking.fetchup.io" target="_black"
                >Parking</b-link
              >
            </b-dropdown-item-button>
          </b-dropdown>
          <!-- <b-nav-item>
            <router-link to="/SignIn" class="nav-link"
              ><b-button pill variant="dark"
                >Sign Up/Log In</b-button
              ></router-link
            >
          </b-nav-item> -->
          <div class="fullnav"></div>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <b-sidebar
      id="sidebar-no-header"
      aria-labelledby="sidebar-no-header-title"
      no-header
      shadow
      backdrop
    >
      <template #default="{ hide }">
        <div class="p-3">
          <h4 id="sidebar-no-header-title" @click="hide">FetchUp</h4>
          <nav class="mb-3">
            <b-nav vertical>
              <b-nav-item to="/" @click="hide">{{ $t("home") }}</b-nav-item>
              <b-nav-item to="/Services" @click="hide">{{
                $t("headerServices")
              }}</b-nav-item>
              <b-nav-item to="/Stores" @click="hide">{{
                $t("headerStores")
              }}</b-nav-item>
              <b-nav-item to="/Plazas" @click="hide">{{
                $t("headerPlazas")
              }}</b-nav-item>
              <b-nav-item to="/Us" @click="hide">{{
                $t("headerUs")
              }}</b-nav-item>
            </b-nav>
          </nav>
        </div>
      </template>
      <template #footer>
        <div class="ft-style d-flex text-light align-items-center px-4 py-2">
          <b-dropdown right variant="light">
            <template #button-content>
              <b-icon icon="globe" aria-hidden="false"></b-icon>
            </template>
            <b-dropdown-item @click="$helpers.changeLanguage('es')">{{
              $t("es")
            }}</b-dropdown-item>
            <b-dropdown-item @click="$helpers.changeLanguage('en')">{{
              $t("en")
            }}</b-dropdown-item>
          </b-dropdown>
        </div>
        <div class="ft-style d-flex text-light align-items-center px-4 py-2">
          <strong
            >Fetchup {{ new Date().getFullYear() }}. All rights
            reserved.</strong
          >
        </div>
      </template>
    </b-sidebar>
    <transition name="fade">
      <router-view
        :class="$i18n.locale"
        :data-lang="$i18n.locale"
      ></router-view>
    </transition>
  </b-container>
</template>

<script>
export default {
  props: {
    msg: String,
  },
};
</script>
<style>
.fade-enter-active {
  transition: all 0s ease-in;
}
.fade-leave-active {
  transition: all 0s ease-in;
}
.fade-enter-to,
.fade-leave-to {
  opacity: 0;
}
</style>
<style lang="scss" scoped>
#header {
  font-family: "DMSans", Roboto, Arial, sans-serif !important;
  font-weight: bold;
  position: fixed;
  box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.2);
  z-index: 999;
  width: 100%;
  padding: 0rem 1rem;
  font-size: 1vw;
}
.nav-link {
  color: gray !important;
  display: flex;
  align-items: center;
  width: 100%;
  height: 7.25vh;
  img {
    width: 12.5vw;
  }
  button {
    font-size: 1vw;
  }
}
.nav-item {
  vertical-align: middle;
  /* margin: 0 60px; */
}
.ft-style {
  color: gray !important;
  width: 100%;
  padding: 0 !important;
  text-align: center;
  justify-content: center;
}
/* TODO: Completar animación de menu */
.fullnav {
  position: absolute;
  width: 100%;
  // height: 5px;
  bottom: 0;
  background: #dc4510;
}
.navbar-nav {
  width: 65%;
  justify-content: space-between;
}
.navbar-nav .router-link-active {
  position: relative;
  color: black !important;
}
.navbar-nav .services.router-link-active::before {
  content: "";
  position: absolute;
  background: #dc4510;
  height: 30%;
  top: 25%;
  border-radius: 1000px;
  width: 80%;
  transform: translateX(10%);
  animation-name: animationMenuService;
  animation-duration: 0.75s;
  z-index: -1;
}
.navbar-nav .stores.router-link-active::before {
  content: "";
  position: absolute;
  background: #dc4510;
  height: 30%;
  bottom: 25%;
  border-radius: 1000px;
  width: 100%;
  transform: translateX(-20%);
  animation-name: animationMenuStore;
  animation-duration: 0.75s;
  z-index: -1;
}
.navbar-nav .plazas.router-link-active::before {
  content: "";
  position: absolute;
  background: #e58d21;
  height: 30%;
  top: 25%;
  border-radius: 1000px;
  width: 100%;
  transform: translateX(-20%);
  animation-name: animationMenuPlazas;
  animation-duration: 0.75s;
  z-index: -1;
}
.navbar-nav .us.router-link-active::before {
  content: "";
  position: absolute;
  background: #e58d21;
  height: 30%;
  bottom: 25%;
  border-radius: 1000px;
  width: 100%;
  transform: translateX(-12.5%);
  animation-name: animationMenuUs;
  animation-duration: 0.75s;
  z-index: -1;
}
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}
.dropdown {
  li {
    margin: 0 0px;
    width: 100%;
  }
  a {
    color: unset;
    text-decoration: unset;
    display: block;
    width: 100%;
  }
}

a {
  color: #42b983;
}
.login-btn {
  .btn {
    border-radius: 50rem !important;
    color: #fff !important;
    background-color: #343a40 !important;
    border-color: #343a40 !important;
  }
}
@keyframes animationMenuService {
  from {
    transform: translateY(-100vh) translateX(0%);
  }
  to {
    transform: translateY(0vh) translateX(10%);
  }
}

@keyframes animationMenuStore {
  from {
    transform: translateY(-100vh) translateX(0%);
  }
  to {
    transform: translateY(0vh) translateX(-20%);
  }
}

@keyframes animationMenuPlazas {
  from {
    transform: translateY(-100vh) translateX(0%);
  }
  to {
    transform: translateY(0vh) translateX(-20%);
  }
}

@keyframes animationMenuUs {
  from {
    transform: translateY(-100vh) translateX(0%);
  }
  to {
    transform: translateY(0vh) translateX(-12.5%);
  }
}
</style>
