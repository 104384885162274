<template>
  <!-- SLIDE 2 -->
  <div class="section change-game">
    <transition name="slide-fade">
      <div class="container-fluid h-100" v-if="active >= 1">
        <div class="row align-items-center h-100 col-pfu">
          <div
            class="col-fu col-sm-6 col-md-6 col-lg-6 h-100 img-friends"
          ></div>
          <div class="col-fu col-sm-6 col-md-6 col-lg-6 h-100">
            <div class="container h-100">
              <div class="row align-items-end h-50">
                <div class="col change-game-text">
                  <h1 class="ml-1">
                    <div v-html="$t('change_game')"></div>
                  </h1>
                </div>
              </div>
              <div class="row align-items-start h-50">
                <div class="col-9">
                  <div
                    class="text-left text-muted text-description"
                    v-html="$t('homeChangeSubt')"
                  ></div>
                  <div class="row h-25 align-items-start button-modal">
                    <b-button
                      pill
                      variant="outline-primary"
                      v-b-modal.modal-video-change
                    >
                      <b-icon-play-fill></b-icon-play-fill>
                      {{ $t("homeWatchVideo") }}</b-button
                    >
                  </div>
                </div>
                <div class="col-3"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <b-modal
      id="modal-video-change"
      size="xl"
      centered
      :hide-footer="true"
      :hide-header="true"
      class="video"
    >
      <b-embed
        type="iframe"
        aspect="16by9"
        src="https://www.youtube.com/embed/Fb5zXfOfrg0?autoplay=1"
        crossorigin="anonymous"
        autoplay
        allowfullscreen
      ></b-embed>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    active: Number,
  },
};
</script>
<style lang="scss">
.change-game {
  font-family: "DMSans", Roboto, Arial, sans-serif !important;

  .img-friends {
    background-image: url("/assets/2ndSlideFriends.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    z-index: 1;
    animation-name: animation-img-friends;
    animation-duration: 2s;
  }

  .change-game-text {
    height: 100%;
    display: flex;
    text-align: start;
    vertical-align: bottom;
    align-items: center;
    padding-top: 10%;
    animation-name: animation-change-game-texts;
    animation-duration: 2s;
    z-index: 1;

    h1 {
      transform: translateX(-8.5vw);
      z-index: 1;
      div {
        font-size: 3.5vw;
        font-weight: 800;
        color: black;
        //text-shadow: 2px 2px 6px grey;
        span {
          color: white;
          position: relative;
          //text-shadow: -2px -2px 4px black;
        }
        span::before {
          content: "";
          position: absolute;
          top: 32.5%;
          left: 0;
          height: 70%;
          background-color: #dc4510cc;
          border-radius: 1000px;
          width: 110%;
          transform: translateX(-5%);
          z-index: -1;
          animation-name: animation-change-game-span;
          animation-duration: 2s;
        }
      }
      div::after {
        content: "";
        position: absolute;
        background-image: url("/assets/3rdSlidePie.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        transform: translateX(-25%) translateY(-40%);
        width: 16vw;
        height: 30vh;
        z-index: -1;
        animation-name: animationOpacityImg;
        animation-duration: 2.5s;
      }
    }
  }

  .text-description {
    font-size: 1vw;
    font-weight: 600;
    animation-name: animation-change-game-texts;
    animation-duration: 2s;
  }

  .button-modal {
    margin-top: 5%;
    animation-name: animation-change-game-texts;
    animation-duration: 2s;
    .btn-outline-primary {
      background-color: transparent !important;
      border-color: #dc4510cc !important;
      color: #dc4510cc !important;
      font-weight: normal !important;
      font-size: 1.5vw !important;
      border-width: 2px !important;
    }
  }
}

.es {
  .change-game-text {
    width: 300px;
    h1 {
      div {
        width: 80%;
        span:nth-child(2):before {
          content: "";
          position: absolute;
          top: 32.5%;
          left: -20%;
          height: 70%;
          background-color: rgba(220, 159, 16, 0.8);
          border-radius: 1000px;
          width: 160%;
          transform: translateX(-5%);
          z-index: -1;
          animation-name: animation-change-game-span;
          animation-duration: 2s;
        }
      }
      div::after {
        transform: translateX(135%) translateY(-70%);
      }
    }
  }
}

@keyframes animation-img-friends {
  from {
    transform: translateY(100vh);
  }
  to {
    transform: translateY(0vh);
  }
}

@keyframes animation-change-game-texts {
  from {
    transform: translateX(100vw);
  }
  to {
    transform: translateX(0vw);
  }
}

@keyframes animation-change-game-span {
  from {
    transform: translateX(100vw);
  }
  to {
    transform: translateX(-5%);
  }
}
</style>