const getLang = navigator.language || navigator.userLanguage;
let lang = "es";

if (getLang.substr(0, 2) == "es") {
  lang = "es";
} else if (getLang.substr(0, 2) == "en") {
  lang = "en";
} else {
  lang = "en";
}

export default {
  state: {
    language: localStorage.language == null ? lang : localStorage.language,
  }
}