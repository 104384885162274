import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'

import i18n from "./i18n";
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'

import 'fullpage.js/vendors/scrolloverflow' // Optional. When using scrollOverflow:true
//import './fullpage.scrollHorizontally.min' // Optional. When using fullpage extensions
import VueFullPage from 'vue-fullpage.js'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import helpers from "./helpers";
Vue.prototype.$helpers = helpers;

Vue.use(BootstrapVue)
Vue.use(i18n)
Vue.use(BootstrapVueIcons)
Vue.use(VueFullPage)
Vue.config.productionTip = false
new Vue({
    router,
    render: h => h(App),
    i18n
}).$mount('#app')