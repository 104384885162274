<template>
  <div class="servicesScreen">
    <div class="container-fluid position-absolute" id="subMenu">
      <div class="row align-items-center m-4 h-100">
        <div
          :class="[
            activePage >= 1 ? 'select-page-grey' : 'select-page-white',
            sectionPark && activePage >= 1 ? 'active-park' : '',
          ]"
          style="cursor: pointer"
          @click="reloadFullPage(true, false)"
        >
          <strong>{{ $t("park") }}</strong>
        </div>
        <div
          :class="[
            activePage >= 1 ? 'select-page-grey' : 'select-page-white',
            sectionShop && activePage >= 1 ? 'active-shop' : '',
          ]"
          style="cursor: pointer"
          @click="reloadFullPage(false, true)"
        >
          <strong>{{ $t("shop") }}</strong>
        </div>
      </div>
      <div class="row align-items-center">
        <div id="hrSubMenu"></div>
      </div>
    </div>
    <full-page ref="fullpage" :options="options" :key="keyReload">
      <!-- SLIDE 1 -->
      <div class="section" id="slide1">
        <div class="container h-100">
          <div class="row h-50 align-items-center">
            <div class="col h-50"></div>
          </div>
          <div class="row align-items-start h-50">
            <div class="col h-100">
              <div class="container h-100">
                <div class="row h-25 align-items-start">
                  <div class="col"></div>
                </div>
                <div class="row h-25 align-items-center">
                  <div class="col">
                    <h1 id="newMall">{{ $t("mallsmart") }}</h1>
                  </div>
                </div>
                <div id="divBtnVideo" class="row h-25 align-items-center">
                  <div class="col">
                    <b-button
                      pill
                      variant="outline-primary"
                      class="btnWatchVideo"
                      v-b-modal.modal-video-services
                    >
                      <b-icon-play-fill></b-icon-play-fill>
                      {{ $t("watchVideo") }}</b-button
                    >
                  </div>
                </div>
                <div class="row h-25 align-items-center">
                  <div class="col">
                    <div class="container">
                      <div class="row stores">
                        <div class="col-6 text-right">
                          <a
                            target="blanck"
                            href="https://apps.apple.com/mx/app/fetchup/id1529489977"
                          >
                            <img src="/assets/logoWhiteAppStore.svg" />
                          </a>
                        </div>
                        <div class="col-6 text-left">
                          <a
                            target="blanck"
                            href="https://play.google.com/store/apps/details?id=com.smartcity.fetchup.mx"
                          >
                            <img src="/assets/logoWhiteGooglePlay.svg"
                          /></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <b-modal
          id="modal-video-services"
          size="xl"
          centered
          :hide-footer="true"
          :hide-header="true"
          class="video"
        >
          <b-embed
            type="iframe"
            aspect="16by9"
            src="https://www.youtube.com/embed/bbZkwYhE-2c?autoplay=1"
            crossorigin="anonymous"
            autoplay
            allowfullscreen
          ></b-embed>
        </b-modal>
      </div>
      <ParkingS2
        v-if="sectionPark || !sectionShop"
        :active="activePage"
      ></ParkingS2>
      <ParkingS1
        v-if="sectionPark || !sectionShop"
        :active="activePage"
      ></ParkingS1>
      <ParkingS3
        v-if="sectionPark || !sectionShop"
        :active="activePage"
      ></ParkingS3>

      <ShoppingS2 v-if="sectionShop" :active="activePage"></ShoppingS2>
      <ShoppingS1 v-if="sectionShop" :active="activePage"></ShoppingS1>
      <ShoppingS3 v-if="sectionShop" :active="activePage"></ShoppingS3>
      <Footer></Footer>
    </full-page>
  </div>
</template>
<script>
import Footer from "@/views/Footer";
import ParkingS1 from "@/views/Services/ParkingS1";
import ParkingS2 from "@/views/Services/ParkingS2";
import ParkingS3 from "@/views/Services/ParkingS3";
import ShoppingS1 from "@/views/Services/ShoppingS1";
import ShoppingS2 from "@/views/Services/ShoppingS2";
import ShoppingS3 from "@/views/Services/ShoppingS3";
export default {
  components: {
    Footer,
    ParkingS1,
    ParkingS2,
    ParkingS3,
    ShoppingS1,
    ShoppingS2,
    ShoppingS3,
  },
  data() {
    return {
      options: {
        licenseKey: "7FD5911B-F38C4985-A35FC134-394A56DE",
        scrollBar: false,
        navigation: true,
        onLeave: this.onLeaveMeth,
      },
      sectionPark: false,
      sectionShop: false,
      activePage: 0,
      keyReload: 0,
    };
  },
  mounted() {
    if (this.$route.query.shop) this.reloadFullPage(false, true);
    if (this.$route.query.park) this.reloadFullPage(true, false);
  },
  methods: {
    onLeaveMeth(origin, destination) {
      // function (origin, destination)
      //let o = origin.index || 0;
      let d = destination.index || 0;
      this.activePage = d;
      if (!this.sectionPark && !this.sectionShop) this.sectionPark = true;
    },
    reloadFullPage(p, s) {
      this.sectionPark = p;
      this.sectionShop = s;
      this.$refs.fullpage.api.moveTo(1, 1);
      setTimeout(() => {
        this.keyReload++;
      }, 500);
      setTimeout(() => {
        this.$refs.fullpage.api.moveTo(2, 1);
      }, 600);
    },
  },
};
</script>
<style>
.slide-fades-enter-active {
  transition: all 0s ease-in;
}
.slide-fades-leave-active {
  transition: all 0.5s ease-out;
}
.slide-fades-leave-to {
  /* transform: translateX(50vw) translateY(-200vh); */
  opacity: 0;
}
</style>
<style lang="scss">
.servicesScreen {
  font-family: "DMSans", Roboto, Arial, sans-serif !important;
  .section {
    background-repeat: no-repeat;
  }
  #subMenu {
    :nth-child(1) {
      justify-content: center;
      text-align: center;
      :nth-child(1) {
        margin-right: 0.5vw;
      }
      :nth-child(2) {
        margin-left: 0.5vw;
      }
    }
    margin-top: 8vh;
    font-size: 1.5vw !important;
    z-index: 100;
    .select-page-white {
      color: white;
    }
    .select-page-grey {
      color: #d3d3d3;
    }
    .active-park {
      color: black;
      position: relative;
      ::before {
        content: "";
        position: absolute;
        top: 0;
        left: -27.5%;
        height: 70%;
        background-color: #dc4510;
        border-radius: 1000px;
        transition: all 0s ease-in;
        animation-name: animationMenuService;
        animation-duration: 0.75s;
        width: 100%;
        z-index: -1;
      }
    }
    .active-shop {
      color: black;
      position: relative;
      ::before {
        content: "";
        position: absolute;
        top: 35%;
        left: 20.5%;
        height: 70%;
        background-color: #e58d21;
        border-radius: 1000px;
        transition: all 0s ease-in;
        animation-name: animationMenuService;
        animation-duration: 0.75s;
        width: 100%;
        z-index: -1;
      }
    }
  }
  #hrSubMenu {
    position: relative;
    height: 0.25vh;
    left: 40%;
    width: 20vw;
    background-color: #d3d3d3;
  }
  #slide1 {
    background-image: url("/assets/backgroundServices.png");
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .btn-outline-primary {
    background-color: transparent !important;
    border-color: white !important;
    color: white !important;
    font-weight: normal !important;
    font-size: 1.5vw !important;
    border-width: 2px !important;
  }
  /* SLIDE 1 */
  #newMall {
    color: white;
    transform: translateY(-8.75vh);
    font-size: 2vw;
    //text-shadow: 2px 2px 4px black;
  }
  #divBtnVideo {
    padding-bottom: 5vh;
  }
  .stores {
    img {
      width: 10.5vw;
    }
  }

  @keyframes animationMenuService {
    from {
      transform: translateY(-100vh);
    }
    to {
      transform: translateY(0vh);
    }
  }

  @keyframes animationOfBottom {
    from {
      transform: translateY(100vh);
    }
    to {
      transform: translateY(0vh);
    }
  }

  @keyframes animationOpacityImg {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    75% {
      opacity: 0.2;
    }
    100% {
      opacity: 1;
    }
  }
}
</style>